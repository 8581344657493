<template>
  <div
    :class="{ active: active === val.id }"
    class="matrix-scale"
    @click="divClick"
  >
    <div class="head">
      <div class="tag" :id="`tag-${val.id}`">
        <slot name="sort"> </slot>
        多选
      </div>
      <div class="question-handle">
        <i class="el-icon-delete" @click="$emit('del-question', val)"></i>
      </div>
    </div>
    <div class="input-container">
      <div class="input-item">
        <el-input
          v-model="val.title"
          placeholder="请输入题目标题"
          class="common-input"
          @change="$emit('change', val)"
        />
      </div>
      <div class="input-item">
        <el-input
          v-model="subjectInfo.value"
          placeholder="请输入题目说明(选填)"
          class="common-input"
          @change="$emit('change', val)"
        />
      </div>
      <el-checkbox-group v-model="subjectInfo.checkList" @change="checkChange">
        <div
          contenteditable
          class="input-item"
          v-for="(item, index) in subjectInfo.list"
          :key="index"
        >
          <div class="exclusive" v-if="item.exclusive">
            <el-tag size="mini" type="info">排他</el-tag>
          </div>
          <el-checkbox :label="item" class="checkbox"></el-checkbox>
          <div
            contenteditable
            class="editLabel"
            @input="listLabelChange($event, index)"
          >
            {{ item.value }}
          </div>
          <div class="text"></div>
          <div class="delete" @click="deleteRadio(index)">
            <i class="el-icon-close"></i>
          </div>
        </div>
      </el-checkbox-group>
    </div>
    <div class="divider"></div>
    <div class="handle">
      <el-button type="text" class="handle-btn">
        <span @click="addRow">
          <i class="el-icon-circle-plus-outline"></i>
          添加行
        </span>
        <span class="rightLine"></span>
        <span @click="editAll">
          <i class="el-icon-folder-add"></i>
          批量编辑
        </span>
      </el-button>
    </div>

    <el-drawer
      title="批量编辑"
      append-to-body
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <div v-for="(item, index) in subjectInfo.list" :key="index">
        <el-input
          class="all-input"
          v-model="item.value"
          placeholder=""
        ></el-input>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import twoWay from "@/utils/two-way";
export default {
  name: "MultipleSelection",
  mixins: [twoWay],
  props: {
    global: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Number,
      default: 0,
    },
  },
  components: {
    // draggable
  },
  data() {
    return {
      drawer: false,
      direction: "rtl",
    };
  },
  computed: {
    subjectInfo() {
      return this.val.subjectJson;
    },
  },
  methods: {
    addRow() {
      this.subjectInfo.list.push({
        value: "选项",
        exclusive: false,
        disabled: true,
      });
    },
    divClick() {
      this.$emit("focus-view", this.val);
      this.$bus.$emit("getCheckBoxList", this.val);
    },
    checkChange(val) {
      console.log(val);
      let obj = this.subjectInfo.checkList.find(
        (item) => item.exclusive == true
      );
      if (obj) {
        this.subjectInfo.checkList = [obj];
      }
      console.log(obj);
    },
    editAll() {
      this.drawer = true;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    deleteRadio(selectedIndex) {
      if (this.subjectInfo.list.length <= 1) {
        this.$message.error("最少有一项");
      } else {
        this.subjectInfo.list.splice(selectedIndex, 1);
      }
    },
    listLabelChange(e, index) {
      const str = e.target.innerText;
      console.log(str, index);
      this.subjectInfo.list[index].value = str;
    },
  },
};
</script>

<style lang="scss" scoped>
.matrix-scale {
  padding: 10px 43px;
  transition: 0.3s;
  margin-bottom: 20px;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &:hover,
  &.active {
    .question-handle {
      display: block;
      z-index: 9999;
    }
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .tag {
    display: inline-block;
    margin-top: 18px;
    margin-bottom: 18px;
    padding: 2px 7px;
    line-height: 27px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 2px 2px 2px 2px;
    font-size: 14px;
    color: #3d3d3d;
  }

  .radio-class {
    align-items: center;
  }

  .radio-active {
    border: 1px solid #3f9eff;
  }

  .input-item:not(:last-child) {
    margin-bottom: 15px;
    display: flex;
    ::v-deep(.el-input__inner) {
      border: none;
      margin: 0;
      padding: 0;
    }
  }

  .input-item {
    .el-checkbox__label {
      display: flex;
      .el-input:nth-child(1) {
        width: fit-content;
      }
      .el-input:nth-child(2) {
        ::v-deep(.el-input__inner) {
          height: 20px !important;
        }
      }
    }
  }

  .input-item {
    margin-bottom: 15px;
    display: flex;
    position: relative;

    ::v-deep(.el-input__inner) {
      border: none;
      margin: 0;
      padding: 0;
    }

    ::v-deep(.el-checkbox__label) {
      display: none;
    }
    .exclusive{
      margin-top:6px;
      margin-right: 5px;
    }
    .checkbox{
      margin-top: 6px;
    }
    .editLabel {
      height: 100%;
      font-size: 16px;
      line-height: 40px;
      margin-right: 30px;
      font-size: 16px;
      margin-left: 20px;
    }

    .text {
      width: 100px;
      height: 15px;
      border-bottom: 1px solid black;
    }

    .delete {
      position: absolute;
      right: 0;
      width:50px;
      height: 50px;
    }
  }

  .delete {
    cursor: pointer;
  }

  .radio-class:nth-last-child(1) {
    display: flex;
    align-items: center;
    .text {
      width: 100px;
      color: #3d3d3d;
      margin-bottom: 6px;
      margin-left: -20px;
      font-size: 13px;
      padding-left: 6px;
      border-top: 0px;
      border-right: 0px;
      border-left: 0px;
      outline: none;
      border-bottom: 1px solid #3d3d3d;
    }
  }

  .divider {
    width: 100%;
    border-top: 1px solid #d8d8d8;
  }

  .handle {
    padding-top: 11px;
  }

  .handle-btn {
    color: #3d3d3d;
    i {
      padding: 0 6px;
    }
  }
  .rightLine {
    border-right: 1px solid #d8d8d8;
  }

  .common-input {
    ::v-deep {
      input,
      textarea {
        padding-left: 0;
        border-color: transparent;

        &:hover {
          border-color: #008755;
          border-style: dashed;
        }

        &:focus {
          border-color: #008755;
          border-style: solid;
        }
      }

      .el-input-group__prepend {
        border: none;
        background: transparent;
      }
    }
  }
}
</style>
