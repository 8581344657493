<template>
  <div id="app-mobile" class="box">
    <div class="title">{{ questionModel.title }}</div>
    <div class="content">{{ questionModel.remark }}</div>
    <div class="btn">
      <van-button class="btnLeft">开始答题</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  props: {
    questionModel: {
      default: {}
    }
  },
  created() {},
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  background: #f4f5f8;
  .title {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    font-size: 24px;
    font-weight: 540;
  }
  .content {
    padding: 30px 15px 100px;
    font-size: 12px;
    line-height: 20px;
  }
  .btn {
    display: flex;
    justify-content: center;
    .btnLeft {
      font-size: 12px;
      padding: 0 30px;
      border-radius: 5px;
      background: #3a8557;
      color: #fff;
    }
  }
}
</style>
