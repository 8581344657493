<template>
  <div
    :class="{ active: active === val.id }"
    class="matrix-scale"
    @click="$emit('focus-view', val)"
  >
    <div class="head">
      <div class="tag" :id="`tag-${val.id}`">
        <slot name="sort"> </slot>
        多行文本
      </div>
      <div class="question-handle">
        <i class="el-icon-delete" @click="$emit('del-question', val)"></i>
      </div>
    </div>
    <div class="input-contanier">
      <div class="input-item">
        <el-input
          v-model="val.title"
          placeholder="请输入标题"
          class="common-input"
          @change="$emit('change', val)"
        />
      </div>
      <div class="input-item">
        <el-input
          v-model="val.subjectJson.value"
          type="textarea"
          placeholder="请输入"
          class="common-input"
          @change="$emit('change', val)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import twoWay from "@/utils/two-way";
// import draggable from "vuedraggable";



export default {
  name: "scale",
  mixins: [twoWay],
  props: {
    global: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Number,
      default: 0,
    },
  },
  components: {
    // draggable
  },
  data() {
    return {
    
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.matrix-scale {
  padding: 10px 43px;
  transition: 0.3s;
  margin-bottom: 20px;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &:hover,
  &.active {
    .question-handle {
      display: block;
    }

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .tag {
    display: inline-block;
    margin-top: 18px;
    margin-bottom: 18px;
    padding: 2px 7px;
    line-height: 27px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 2px 2px 2px 2px;
    font-size: 14px;
    color: #3d3d3d;
  }

  .input-item {
    margin-bottom: 15px;
  }

  .common-input {
    ::v-deep {
      input,
      textarea {
        padding-left: 0;
        border-color: transparent;

        &:hover {
          border-color: #008755;
          border-style: dashed;
        }

        &:focus {
          border-color: #008755;
          border-style: solid;
        }
      }

      .el-input-group__prepend {
        border: none;
        background: transparent;
      }
    }
  }
}
</style>
