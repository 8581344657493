import { render, staticRenderFns } from "./preview1.vue?vue&type=template&id=f9566694&scoped=true&"
import script from "./preview1.vue?vue&type=script&lang=js&"
export * from "./preview1.vue?vue&type=script&lang=js&"
import style0 from "./preview1.vue?vue&type=style&index=0&id=f9566694&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9566694",
  null
  
)

export default component.exports