<template>
  <div id="app-mobile" class="box">
    <van-form :label-width="110" label-align="right">
      <div class="boxTop">
        <div class="boxTopTitle">
          <div class="title">{{ questionModel.title }}</div>
          <div class="remark">{{ questionModel.remark }}</div>
        </div>
        <div class="boxTopFrom">
          <van-field
            readonly
            required
            clickable
            disabled
            name="object"
            :value="userInfo.nickname"
            label="请选择评价对象"
            placeholder="请选择评价对象"
            right-icon="arrow"
            :rules="[{ required: true }]"
          />
          <van-field v-model="userInfo.department" name="department" label="所属部门" disabled />
        </div>
      </div>
      <div class="boxContent" v-for="(item, ind) in subjectList[1]" :key="ind">
        <div v-if="item.type == 'text' || item.type == 'matrix'" class="contentTitle">{{ item.data.title }}</div>
        <div v-if="item.type == 'matrix'" class="contentTip">1分表示非常不满意，10分表示非常满意</div>
        <div v-if="item.type == 'text'" class="text-container">
          <el-input v-model="item.data.subjectJson.value" type="textarea" disabled></el-input>
        </div>
        <div class="contentAnswer" v-for="(v, d) in item.data.subjectJson.lines" :key="d">
          <div>{{ v.title }}</div>
          <div class="contentRate">
            <van-field name="rate" required class="rate" :rules="[{ message: '请选择' }]">
              <template #input>
                <van-rate size="25" color="#FF9D26" :count="item.data.subjectJson.dimensionality" />
              </template>
            </van-field>
          </div>
        </div>
      </div>
      <div class="boxBtn">
        <van-button class="button" round block type="primary" native-type="submit">提交答案</van-button>
        <van-button class="button" round block type="primary" native-type="submit">下一个</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: "questionContent",
  data() {
    return {
    };
  },
  props: {
    subjectList: {
      default: {}
    },
    questionModel: {
      default: {}
    },
    selectList: {
      default: []
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  },
  mounted() {
    // console.log(this.userInfo, 'userInfo')
    this.subjectList[1] = this.subjectList[1].filter(item => (item.type == 'text' || item.type == 'matrix'))
    console.log(this.subjectList[1],'subjectList');
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.text-container {
  margin-top: 15px;
}
.box {
  background: #f4f5f8;
}
.boxTop {
  background: #fff;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .boxTopTitle {
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    .title {
      font-size: 16px;
      padding-bottom: 10px;
    }
    .remark {
      font-size: 12px;
      color: #636363;
    }
  }
  .boxTopFrom {
    width: 100%;
    padding: 0;
    ::v-deep .van-cell {
      padding: 5px 16px;
      font-size: 5.5px;
      .van-cell__title {
        width: 90px !important;
      }
      .van-field__right-icon {
        padding: 0;
      }
    }
    ::v-deep .van-cell::after {
      border-bottom: none;
    }
  }
}
.department {
  ::v-deep .van-picker-column {
    font-size: 24px;
  }
  ::v-deep .van-picker__toolbar {
    height: 50px;
  }
  ::v-deep .van-picker__cancel {
    font-size: 8px;
  }
  ::v-deep .van-picker__confirm {
    font-size: 20px;
  }
}
.boxContent {
  background: #fff;
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
  .contentTitle {
    font-size: 16px;
  }
  .contentTip {
    font-size: 14px;
    padding: 5px 0;
    line-height: 24px;
    color: #636363;
  }
  .contentRate {
    margin-bottom: 10px;
    ::v-deep .van-cell {
      padding: 0 16px;
    }
  }
}
.boxBtn {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  .button {
    height: 40px;
    width: 40%;
    font-size: 12px;
    border-radius: 5px;
    background: #008755;
    margin-bottom: 50px;
  }
}
.rate {
  ::v-deep .van-rate {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
</style>
