<template>
  <div class="end-page">
    <div class="message">
      <dl class="messageDl">
        <dt><img src="@/assets/切图 22.png" alt=""></dt>
        <dd>问卷到此结束,感谢评价!</dd>
      </dl>
    </div>
    <div class="handle">
      <div class="block">
        <van-button type="info" class="handle-btn">下一个</van-button>
      </div>
      <div class="block">
        <van-button class="handle-btn">返回首页</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.message {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  font-size: 20px;
  font-weight: bold;
  .messageDl{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    dt{
      margin-bottom: 15%;
    }
  }
}
.handle {
  margin-top: 100px;
  .block {
    margin-bottom: 10px;
    text-align: center;
  }
  .handle-btn {
    width: 80%;
  }
}
</style>