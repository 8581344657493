<template>
  <div class="menu-outline">
    <!-- {{val}} -->
    <div v-for="(value, index) in list" :key="value.id" class="page">
      <div class="page-num">
        第{{ gettoChinesNum(index + 1) }}页
        <div class="handle">
          <i v-show="index !== '第一页'" class="el-icon-close" @click="delPage(val)"></i>
        </div>
      </div>
      <draggable v-model="list[index]" @sort="$emit('sort-change',list)">
        <transition-group key="index">
          <div v-for="(question, index) in list[index]" :key="index" class="page-title"
            @click="scrollPage(question.id, question.page)">
            <img :src="iconTitle" alt="" class="title-icon">
            {{ question.title }}
          </div>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
import twoWay from '@/utils/two-way';
import iconTitle from '@/assets/icon-title-before.png';
import { toChinesNum } from '@/utils/tools';
import draggable from 'vuedraggable';

export default {
  name: 'outline',
  mixins: [twoWay],
  data() {
    return {
      iconTitle,
      list: []
    };
  },
  computed: {
    getQuestion() {
      const list = {};
      Object.keys(this.val).forEach(key => {
        list[`第${toChinesNum(key)}页`] = this.val[key];
      });
      return list;
    },
  },
  mounted() {
    this.getlist()
  },
  methods: {
    scrollPage(id, page) {
      this.$emit('change-page', page);
      this.$nextTick(() => {
        document.getElementById(`tag-${id}`).scrollIntoView({ behavior: "smooth", block: "center" });
      });
    },
    delPage(val) {
      this.$confirm('是否确认删除?').then(() => {
        const page = val[0].page;
        const ids = val.map(item => item.id);
        this.$emit('del-page', {
          page, ids
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    getlist() {
      const list = [];
      Object.keys(this.val).forEach(key => {
        console.log(key);
        console.log(this.val);
        list[key - 1] = this.val[key]
      });
      this.list = list
    },
    gettoChinesNum(index){
      return toChinesNum(index)
    }
  },
  watch:{
    val:{
      handler(){
        this.getlist()
      },
      deep: true,
    }
  },
  components: { draggable }
}
</script>

<style lang="scss" scoped>
.menu-outline {
  padding: 20px 12px;
}

.page {
  margin-bottom: 30px;

  &-num {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 11px;
    color: #3d3d3d;

    &:hover .handle {
      display: block;
    }
  }

  &-title {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #3d3d3d;
    cursor: pointer;
  }

  .title-icon {
    display: block;
    margin-right: 6px;
    width: 14px;
    height: 14px;
  }

  .handle {
    display: none;

    * {
      cursor: pointer;
    }
  }
}
</style>