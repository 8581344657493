<template>
  <div class="ctrl-global">
    <div class="ctrl-item">
      <div class="ctrl-title">显示方式</div>
      <div class="ctrl-content">
        <div
          v-for="item in showTypes"
          :key="item.type"
          class="switch-item"
        >
          {{item.title}}
          <el-switch
            v-model="val[item.type]"
            @change="$emit('change')"
          />
        </div>
      </div>
    </div>
    <!-- <div class="ctrl-item">
      <div class="ctrl-title">谁可以填答</div>
      <div class="ctrl-content">
        <el-select></el-select>
      </div>
    </div> -->
    <!-- <div class="ctrl-item">
      <div class="ctrl-title">谁可以协管</div>
      <div class="ctrl-content">
        <div class="switch-item">
          添加协管员
          <div class="right">
            <el-button
              type="text"
              class="set-btn"
            >
              设置
            </el-button>
            <el-switch></el-switch>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import twoWay from '@/utils/two-way';

export default {
  mixins: [twoWay],
  data() {
    return {
      showTypes: [
        {
          type: 'displayTitleNumber',
          title: '显示题目编号'
        },
        {
          type: 'retreat',
          title: '答题过程可回退'
        },
        {
          type: 'random',
          title: '问卷随机选题'
        },
        {
          type: 'skip',
          title: '答题完成跳转页面'
        },
        {
          type: 'hideCover',
          title: '隐藏移动端封面'
        },
        {
          type: 'hideAds',
          title: '隐藏问卷提交页广告'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.ctrl-global {
  padding: 19px 12px;

  .ctrl-item {
    margin-bottom: 26px;
  }
  .ctrl-title {
    margin-bottom: 16px;
    font-size: 11px;
    font-weight: 500;
    color: #3d3d3d;
  }
  .ctrl-content {
    .switch-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      font-size: 10px;
      color: #3d3d3d;
    }
  }

  .right {
    display: flex;
    align-items: center;
  }

  .set-btn {
    color: #008755;
  }
}
</style>