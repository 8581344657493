<template>
  <div class="q-handle full-page">
    <div class="handle-title">
      <a href="javascript:;" class="exit" @click="$router.push('/pc')">
        <img :src="iconExit" alt="" class="icon-exit" />
        退出编辑
      </a>
      个人问卷/问卷标题
      <el-button class="preview" @click="previewClick">预览</el-button>
    </div>
    <div class="step-wrapper">
      <div class="step-container">
        <div v-for="step in stepList" :key="step.type" :class="{ active: stepActive === step.type }" class="step-item"
          @click="toRouter(step.name, step.type)">
          {{ step.label }}
        </div>
      </div>
      <div class="submit-ctrl">
        <el-button type="primary" size="mini" @click="$message.success('保存成功')">
          保存
        </el-button>
        <el-button size="mini" @click="push">投放</el-button>
        <span class="allsub">
          允许提交
          <el-switch v-model="canSubmit" active-color="#008755" @change="canSubmitChange" />
        </span>
      </div>
    </div>
    <div class="handle-body">
      <div class="left">
        <div class="menus">
          <div v-for="menu in menus" :key="menu.type" :class="{ active: menuActive === menu.type }" class="menu"
            @click="menuActive = menu.type">
            <component :is="menu.type" :active="menuActive === menu.type" class="block" />
            {{ menu.label }}
          </div>
        </div>
        <div class="menu-view">
          <menu-outline v-show="menuActive === 'outline'" @sort-change="sortChange" v-model="pages"
            @change-page="(page) => (currentPage = page)" @del-page="delPage" />
          <menu-question v-show="menuActive === 'question'" @add="questionAdd" />
        </div>
      </div>
      <div class="middle">
        <div class="middle-top">
          <div class="page-list">
            <div class="page-container">
              <i class="el-icon-arrow-left page-ctrl" :class="{ disabled: currentPage === 1 }" @click="toPrev"></i>
              <div class="pages">
                <span v-for="(val, key) in pages" :key="key" :class="{ active: +key === currentPage }" class="page-item"
                  @click="currentPage = +key">
                  {{ key }}
                </span>
              </div>
              <i class="el-icon-arrow-right page-ctrl" :class="{ disabled: currentPage === Object.keys(pages).length }"
                @click="toNext"></i>
            </div>
            <div class="handle">
              <i class="el-icon-document-add" @click="addPage"></i>
            </div>
            <div class="end">结束页</div>
          </div>
        </div>
        <div class="middle-bottom" id="middle-bottom">
          <!-- 问卷标题 -->
          <div class="middle-title">
            <el-input v-model="questionModel.title" class="title-ctrl common-input" @change="createQuestion" />
          </div>
          <!-- 描述 -->
          <div class="middle-prompt">
            <el-input v-model="questionModel.remark" :autosize="true" type="textarea" class="common-input"
              @change="createQuestion" />
          </div>
          <!-- 主体展示 -->
          <div class="question-list">
            <div v-for="(subject, index) in subjectList[currentPage]" :key="subject.index" @click="getSubject(subject)">
              <div v-if="subject.type === 'matrix'" key="matrix">
                <question-matrix-scale class="subject-item" v-model="subject.data" :active="questionCtrlModel.data.id"
                  :global="questionModel" :key="subject.index" @change="addLine" @focus-view="focusView"
                  @del-question="delQuestion">
                  <template #sort>
                    <span class="sort">{{ index + 1 }}</span>
                  </template>
                </question-matrix-scale>
              </div>
              <div v-if="subject.type === 'matrixRadio'" key="matrixRadio">
                <question-matrix-radio class="subject-item" v-model="subject.data" :active="questionCtrlModel.data.id"
                  :global="questionModel" :key="subject.index" @change="addLine" @focus-view="focusView"
                  @del-question="delQuestion">
                  <template #sort>
                    <span class="sort">{{ index + 1 }}</span>
                  </template>
                </question-matrix-radio>
              </div>
              <div v-if="subject.type === 'matrixMultipleSelection'" key="matrixMultipleSelection">
                <question-matrix-multiple-selection class="subject-item" v-model="subject.data"
                  :active="questionCtrlModel.data.id" :global="questionModel" :key="subject.index" @change="addLine"
                  @focus-view="focusView" @del-question="delQuestion">
                  <template #sort>
                    <span class="sort">{{ index + 1 }}</span>
                  </template>
                </question-matrix-multiple-selection>
              </div>
              <div v-if="['scorer', 'markScorer'].includes(subject.type)" key="markScorer">
                <question-scorer class="subject-item" v-model="subject.data" :active="questionCtrlModel.data.id"
                  :key="subject.index" :list="userList" @change="changeUser" @focus-view="focusView"
                  @del-question="delQuestion">
                  <template #sort>
                    {{ index + 1 }}
                  </template>
                </question-scorer>
              </div>
              <div v-if="subject.type === 'text'" key="text">
                <question-text class="subject-item" v-model="subject.data" :active="questionCtrlModel.data.id"
                  :key="subject.index" @change="changeText" @focus-view="focusView" @del-question="delQuestion">
                  <template #sort>
                    {{ index + 1 }}
                  </template>
                </question-text>
              </div>
              <div v-if="subject.type === 'check'" key="check">
                <question-radio class="subject-item" v-model="subject.data" :active="questionCtrlModel.data.id"
                  :key="subject.index" @change="changeRadio" @focus-view="focusView" @del-question="delQuestion">
                  <template #sort>
                    <span class="sort">{{ index + 1 }}</span>
                  </template>
                </question-radio>
              </div>
              <div v-if="subject.type === 'checkbox'" key="checkbox">
                <question-multiple-selection class="subject-item" v-model="subject.data"
                  :active="questionCtrlModel.data.id" :key="subject.index" @change="changeMultipleSelection"
                  @focus-view="focusView" @del-question="delQuestion">
                  <template #sort>
                    <span class="sort">{{ index + 1 }}</span>
                  </template>
                </question-multiple-selection>
              </div>
              <div v-if="subject.type === 'options'" key="options">
                <question-options class="subject-item" v-model="subject.data" :active="questionCtrlModel.data.id"
                  :key="subject.index" @change="changeMultipleSelection" @focus-view="focusView"
                  @del-question="delQuestion">
                  <template #sort>
                    <span class="sort">{{ index + 1 }}</span>
                  </template>
                </question-options>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <el-tabs v-model="tabActive" @tab-click="() => { }">
          <el-tab-pane label="整卷设置" name="global">
            <global-ctrl v-model="questionModel" @change="createQuestion" />
          </el-tab-pane>
          <el-tab-pane label="题目设置" name="question">
            <question-ctrl v-if="Object.keys(questionCtrlModel).length" v-model="questionCtrlModel"
              :scorer-list="scorerList" :mark-scorer-list="markScorerList" @change="questionChange"
              @upDateComponentType="upDateComponentType" />
          </el-tab-pane>
          <el-tab-pane label="选项设置" name="choose" v-if="chooseVisible">
            <choose-ctrl v-model="chooseModel"> </choose-ctrl>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog v-if="dialogVisible" title="预览" :modal-append-to-body="false" :visible.sync="dialogVisible" width="95%"
      top="5vh">
      <preview :questionModel="questionModel" :subjectList="subjectList" :selectList="selectList"></preview>
    </el-dialog>
  </div>
</template>
  
<script>
import iconExit from "@/assets/icon-exit.png";
import outline from "./svg/icon-outline";
import question from "./svg/icon-question";
import setting from "./svg/icon-setting";
import menuOutline from "@/components/menu-outline";
import menuQuestion from "@/components/menu-question";
import globalCtrl from "@/components/ctrls/global";
import questionCtrl from "@/components/ctrls/question";
import chooseCtrl from "@/components/ctrls/choose";
import questionMatrixScale from "@/components/questions/matrix-scale";
import questionMatrixRadio from "@/components/questions/matrix-radio";
import questionMatrixMultipleSelection from "@/components/questions/matrix-multiple-selection";
import questionScorer from "@/components/questions/scorer";
import questionText from "@/components/questions/text";
import questionRadio from "@/components/questions/radio";
import questionMultipleSelection from "@/components/questions/multiple-selection";
import questionOptions from "@/components/questions/options";
import questionDropDown from "@/components/questions/drop-down";
import preview from "@/views/questionnaire/preview";
import apis from "@/apis";
import draggable from "vuedraggable";
import _ from "lodash";
export default {
  name: "handle",
  components: {
    outline,
    question,
    setting,
    menuQuestion,
    menuOutline,
    globalCtrl,
    questionCtrl,
    chooseCtrl,
    questionMatrixScale,
    questionText,
    questionScorer,
    questionRadio,
    questionMultipleSelection,
    questionMatrixRadio,
    questionDropDown,
    questionOptions,
    questionMatrixMultipleSelection,
    draggable,
    preview
  },
  data() {
    return {
      dialogVisible: false,
      selectList: [],
      canSubmit: true,
      tabActive: "global",
      currentPage: 1,
      pages: {
        1: [],
      },
      drag: false,
      value: true,
      menuActive: "outline",
      iconExit,
      stepActive: "edit",
      stepList: [
        {
          label: "编辑",
          type: "edit",
          name: "q-handle",
        },
        {
          label: "设置",
          type: "setting",
        },
        {
          label: "分享",
          type: "share",
          name: "link",
        },
        {
          label: "统计",
          type: "statistics",
          name: "report",
        },
      ],
      menus: [
        {
          label: "大纲",
          type: "outline",
        },
        {
          label: "题型",
          type: "question",
        },
        {
          label: "设置",
          type: "setting",
        },
      ],
      questionModel: {
        answerType: 0,
        displayTitleNumber: true,
        hideAds: true,
        hideCover: true,
        random: true,
        remark:
          "为了给您提供更好的服务，希望您能抽出几分钟时间，将您的感受和建议告诉我门，我们非常钟时每位用户的宝贵意见，期待您的参与！现在我们就马上开始吧！",
        retreat: true,
        skip: true,
        title: "问卷标题",
        type: 1,
      },
      chooseModel: {},
      subjectList: {},
      questionCtrlModel: {},
      userList: [],
      scorerList: [],
      markScorerList: [],
      count: sessionStorage.getItem("asd"),
      chooseVisible: false,
    };
  },
  methods: {
    previewClick() {
      this.dialogVisible = true;
    },
    toRouter(name, type) {
      const query = {
        type,
        id: localStorage.questionId,
        pos: this.active,
      };
      // console.log(query);
      this.$router.push({
        name: name,
        query,
      });
    },
    sortChange(list) {
      // console.log(111);
      // console.log(list);
      this.subjectList[this.currentPage] = this.subjectList[this.currentPage].sort((a, b) => {
        let indexA = list[this.currentPage - 1].findIndex(item => item.id === a.data.id);
        let indexB = list[this.currentPage - 1].findIndex(item => item.id === b.data.id);
        return indexA - indexB;
      });
    },
    push() {
      apis
        .questionSend({
          id: localStorage.questionId,
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "投放成功!",
          });
          this.getData();
        });
    },
    getQueryUsers(id) {
      apis
        .subjectQueryUsers({
          questionnaireId: id,
          type: 2,
        })
        .then(({ data }) => (this.scorerList = data));
      apis
        .subjectQueryUsers({
          questionnaireId: id,
          type: 3,
        })
        .then(({ data }) => (this.markScorerList = data));
    },
    canSubmitChange(canSubmit) {
      apis
        .questionSetCanSubmit({
          canSubmit,
          id: window.localStorage.questionId,
        })
        .then(() => {
          this.$message.success("操作成功");
        });
    },
    toPrev() {
      if (this.currentPage !== 1) {
        this.currentPage--;
      }
    },
    toNext() {
      if (this.currentPage < Object.keys(this.pages).length) {
        this.currentPage++;
      }
    },
    addPage() {
      const pages = this.pages;
      const keys = Object.keys(pages);
      const max = keys[keys.length - 1];
      const next = +max + 1;
      this.currentPage = next;
      this.$set(pages, next, []);
    },
    getUsers() {
      apis.userDirectory().then(({ data }) => {
        this.userList = data;
      });
    },
    questionAdd(type) {
      /**
       *
       * 类型id 和 类型名字 后期如使用的话,按照下面规则添加
       * 如需修改subjectJson数据,按下方if判断修改
       * baseData为基础模块数据
       * subjectJson为模块使用的数据
       */

      // 初始化为矩阵列表数据
      let subjectJson = {
        polarization: 1,
        polarizationStart: "非常不满意",
        polarizationEnd: "非常满意",
        dimensionality: 10,
        startNum: 1,
        sort: "ascending", // descending
        lines: [{ title: "", value: 0 }],
      };

      // 类型id
      const typeMap = {
        matrix: 1,
        scorer: 2,
        markScorer: 3,
        text: 4,
        options: 5,
        check: 6,
        checkbox: 7,
        date: 8,
        img: 9,
        tree: 10,
        matrixRadio: 11,
        matrixMultipleSelection: 12,
      };

      // 类型名字
      const titleMap = {
        matrix: "矩阵列表",
        scorer: "评分人",
        markScorer: "被评分人",
        text: "多行文本",
        options: "下拉框",
        check: "单选",
        checkbox: "多选",
        date: "日期",
        img: "图片",
        tree: "树",
        matrixRadio: "矩形单选",
        matrixMultipleSelection: "矩阵多选",
      };

      // 判断是否为评分人,被评分人
      if (["scorer", "markScorer"].includes(type)) {
        subjectJson = {
          user: [],
          bindScorer: false,
          needDept: false,
          fillType: 1,
          fillScorer: "",
          scorerRecord: {},
        };
      }

      // 判断是否为多行文本
      if (type === "text") {
        subjectJson = {
          title: "",
          value: "",
        };
      }

      // 判断是否为单选

      if (type === "check") {
        subjectJson = {
          radio: "",
          radioList: [{ value: "选项", text: "", disabled: true }],
          selectedIndex: -1,
        };
      }

      // 判断是否为多选
      if (type === "checkbox") {
        subjectJson = {
          value: "",
          checkList: [],
          list: [
            {
              value: "选项1",
              exclusive: false,
              disabled: true,
              checked: false,
              text: "",
            },
            {
              value: "选项2",
              exclusive: false,
              disabled: true,
              checked: false,
              text: "",
            },
          ],
        };
      }

      // 判断是否为下拉框
      if (type === "options") {
        subjectJson = {
          value: "",
          selectValue: "",
          options: [
            {
              value: 1,
              label: "Options1",
            },
          ],
        };
      }

      //判断是否为矩阵单选
      if (type === "matrixRadio") {
        subjectJson = {
          matrixRadio: "",
          matrixRadioList: [{ value: "选项1" }, { value: "选项2" }],
          selectedIndex: -1,
          drawer: false,
          direction: "rtl",
          radio: "",
          tableData: [
            {
              radio: "",
              rowTitle: "行标题1",
            },
          ],
          labelList: ["选项1"],
          currentRow: null,
          delIsShow: false,
          delLabelIsShow: false,
          isTableOrLabel: true,
        };
      }

      //判断是否为矩阵多选
      if (type === "matrixMultipleSelection") {
        subjectJson = {
          matrixRadio: "",
          matrixRadioList: [{ value: "选项1" }, { value: "选项2" }],
          selectedIndex: -1,
          drawer: false,
          direction: "rtl",
          radio: "",
          tableData: [
            {
              checkList: [],
              rowTitle: "行标题1",
            },
          ],
          labelList: ["选项1"],
          currentRow: null,
          delIsShow: false,
          delLabelIsShow: false,
          isTableOrLabel: true,
        };
      }

      // baseData为基础模块数据
      const baseData = {
        name: "描述描述",
        questionnaireId: localStorage.questionId,
        required: true,
        sensitiveInformationCollection: true,
        title: titleMap[type],
        type: typeMap[type],
        questionnairePage: this.currentPage,
      };

      // postData为请求数据,后端整理
      const postData = {
        ...baseData,
        subjectJson: JSON.stringify(subjectJson),
      };

      // model为遍历需要的数据
      const model = {
        index: +new Date(),
        type,
        data: {
          ...baseData,
          subjectJson,
        },
      };
      // 发送请求 获取当前model的id
      apis.subjectAddOrUpdate(postData).then(({ data }) => {
        model.data.id = data.id;
        this.questionCtrlModel = model;
        // console.log(model);
        if (this.subjectList[this.currentPage]) {
          this.subjectList[this.currentPage] = this.subjectList[
            this.currentPage
          ].map((item) => {
            if (item.group) {
              return model;
            }
            return item;
          });
          this.subjectList = [];
          // this.subjectList[this.currentPage].push(model);
        } else {
          this.$set(this.subjectList, this.currentPage, [model]);
        }

        if (this.pages[this.currentPage - 1]) {
          this.pages[this.currentPage - 1].push({
            id: data.id,
            title: data.title,
            page: data.questionnairePage,
          });
        } else {
          this.$set(this.subjectList, this.currentPage, [
            {
              id: data.id,
              title: data.title,
              page: data.questionnairePage,
            },
          ]);
        }

        this.getQuestion(window.localStorage.questionId);
      });
    },
    createQuestion() {
      apis.questionAddOrUpdate(this.questionModel).then(({ data }) => {
        const id = data.id;
        if (!window.localStorage.questionId) {
          this.getQueryUsers(id);
        }
        window.localStorage.questionId = id;
        this.questionModel.id = id;
      });
    },
    addLine(data) {
      const postData = { ...data };
      postData.subjectJson = JSON.stringify(postData.subjectJson);
      apis.subjectAddOrUpdate(postData);
    },
    changeText(data) {
      const postData = { ...data };
      postData.subjectJson = JSON.stringify(postData.subjectJson);
      apis.subjectAddOrUpdate(postData);
    },
    changeUser(data, selectList) {
      const postData = { ...data };
      this.selectList = selectList;
      postData.subjectJson = JSON.stringify(postData.subjectJson);
      apis.subjectAddOrUpdate(postData).then(() => {
        this.getQueryUsers(localStorage.questionId);
      });
    },
    changeRadio(data) {
      const postData = { ...data };
      postData.subjectJson = JSON.stringify(postData.subjectJson);
      apis.subjectAddOrUpdate(postData);
    },
    changeMultipleSelection(data) {
      const postData = { ...data };
      postData.subjectJson = JSON.stringify(postData.subjectJson);
      apis.subjectAddOrUpdate(postData);
    },
    changeMatrixRadio(data) {
      const postData = { ...data };
      postData.subjectJson = JSON.stringify(postData.subjectJson);
      apis.subjectAddOrUpdate(postData);
    },
    focusView(val) {
      const list = this.subjectList;
      Object.keys(list).forEach((key) => {
        const result = list[key].find((item) => item.data.id === val.id);
        if (result) {
          this.questionCtrlModel = result;
        }
      });
    },
    delPage({ page, ids }) {
      apis.subjectDeleteByIds(ids).then(() => {
        this.$delete(this.subjectList, page);
        this.$delete(this.pages, page);
      });
    },
    delQuestion(val) {
      this.$confirm("是否确认删除?")
        .then(() => {
          apis
            .subjectDelete({
              id: val.id,
            })
            .then(() => {
              this.$message.success("删除成功");
              // const list = this.subjectList[this.currentPage];
              // const pageList = this.pages[this.currentPage];
              // const index = list.findIndex((item) => item.id === val.id);
              // list.splice(index, 1);
              // pageList.splice(index, 1);
              this.subjectList = [];
              this.pages = [];
              this.getQuestion(window.localStorage.questionId);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    questionChange({ data }) {
      const postData = { ...data };
      postData.subjectJson = JSON.stringify(postData.subjectJson);
      apis.subjectAddOrUpdate(postData);
    },
    getQuestion(id) {
      window.localStorage.questionId = id;
      apis.questionFindById({ id }).then(({ data }) => {
        this.questionModel = data;
        this.canSubmit = data.canSubmit;
      });
      apis
        .subjectQueryByQuestionnaireId({
          page: this.currentPage,
          questionnaireId: id,
        })
        .then(({ data }) => {
          // console.log(data);
          if (data) {
            let arr = [];
            data.forEach((item) => {
              const page = item.questionnairePage;
              // 下面两个if 为当前的数组创建响应式数据
              if (!this.pages[page]) {
                this.$set(this.pages, page, []);
              }
              if (!this.subjectList[page]) {
                this.$set(this.subjectList, page, []);
              }
              arr.push({
                id: item.id,
                title: item.title
                  ? item.title
                  : item.type === 2
                    ? "评分人"
                    : "被评分人",
                page: item.questionnairePage,
              })
              // console.log(arr);
              this.pages[page] = arr
              // let uniqueArray = this.pages[page].reduce((accumulator, currentValue) => {
              //   if (!accumulator.includes(currentValue.id)) {
              //     accumulator.push(currentValue);
              //   }
              //   return accumulator;
              // }, []);
              // console.log(uniqueArray); // 输出：[1, 2, 3, 4, 5]
            });
            data.forEach((question) => {
              question.subjectJson = JSON.parse(question.subjectJson);
              const typeMap = {
                1: "matrix",
                2: "scorer",
                3: "markScorer",
                4: "text",
                5: "options",
                6: "check",
                7: "checkbox",
                8: "date",
                9: "img",
                10: "tree",
                11: "matrixRadio",
                12: "matrixMultipleSelection",
              };
              const model = {
                index: `${+new Date()}_${Math.random().toFixed(4)}`,
                type: typeMap[question.type],
                data: question,
              };
              let list = [...this.subjectList[question.questionnairePage]];
              list.push(model);
              const newList = list.filter(item => item.data !== undefined)
              this.$set(this.subjectList,[question.questionnairePage],newList)
            });
            if (this.subjectList[1]) {
              this.questionCtrlModel = this.subjectList[1][0];
            }
          }
        });
    },
    upDateComponentType(val, type) {
      // 拿到当前组件的位置
      // subjectList[currentPage]
      const typeMap = {
        1: "matrix",
        2: "scorer",
        3: "markScorer",
        4: "text",
        5: "options",
        6: "check",
        7: "checkbox",
        8: "date",
        9: "img",
        10: "tree",
        11: "matrixRadio",
        12: "matrixMultipleSelection",
      };
      const id = val.data.id;
      this.subjectList[this.currentPage].forEach((item) => {
        if (item.data.id == id) {
          item.type = typeMap[type];
        }
      });
    },
    //拖拽后重新排序
    async getQuestionSort(list) {
      // console.log(list);
      // console.log(111);
      let arr = [];
      Object.keys(list).map((item) => {
        return list[item].map((item1) => {
          if (!item1.data) return;
          arr.push({
            ...item1.data,
            subjectJson: JSON.stringify(item1.data.subjectJson),
          });
        });
      });
      if (arr.length <= 0) return
      // console.log(arr);
      await apis.subjectSort(arr);
      // console.log(this.subjectList[this.currentPage]);
      _.once(() => this.getQuestion(window.localStorage.questionId));
    },
    getSubject(subject) {
      // console.log(subject);
      const showTypeArr = ['check', 'checkbox', 'matrixRadio', 'matrixMultipleSelection']
      let flag = showTypeArr.includes(subject.type)
      this.chooseVisible = flag;
    }
  },
  watch: {
    subjectList: {
      handler(newVal) {
        // console.log(111);
        if (newVal) {
          // console.log(newVal);
          this.getQuestionSort(newVal);
        }
      },
      deep: true
    },
  },
  mounted() {
    const { type, pos, id } = this.$route.query;
    const questionModel = this.questionModel;
    const questionId = window.localStorage.questionId;
    questionModel.type = +pos;
    if (type === "create") {
      if (questionId) {
        this.getQuestion(questionId);
        this.getQueryUsers(questionId);
      } else {
        this.createQuestion();
      }
    }
    if (type === "edit") {
      this.getQuestion(id);
      this.getQueryUsers(id);
    }
    this.getUsers();
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}

.dragClass {
  background: white;
  opacity: 1 !important;
}

.chosen {
  border: solid 2px #3f9eff !important;
}

.allsub {
  font-size: 8px;
  margin-left: 5px;
}

.handle-title {
  position: relative;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  background: #ffffff;
  color: #3d3d3d;

  .exit {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 18px;
    transform: translateY(-50%);
    text-decoration: none;
    font-size: 14px;
    color: #3d3d3d;

    .icon-exit {
      display: block;
      margin-right: 6px;
      width: 16px;
      height: 16px;
    }
  }

  .preview {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.step-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
  padding: 15px 0;
  background: #fff;

  .step-container {
    display: flex;
  }

  .step-item {
    display: block;
    position: relative;
    text-decoration: none;
    font-size: 14px;
    color: #7e7e7e;

    &:last-child::after {
      display: none;
    }

    &::after {
      content: "";
      display: inline-block;
      margin: 0 20px;
      width: 10px;
      height: 10px;
      background: url("~@/assets/icon-step.png");
      background-size: 100% 100%;
    }

    &.active {
      color: #008755;

      &::before {
        content: "";
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 28px;
        height: 2px;
        background: #008755;
        border-radius: 10px 10px 10px 10px;
      }
    }
  }

  .submit-ctrl {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    font-size: 14px;
    color: #3d3d3d;
  }
}

.question-list {
  max-width: 100%;

  div {
    display: block !important;
  }
}

.handle-body {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  height: calc(100% - 100px);

  ::v-deep .question-title {
    font-size: 11px;
  }

  ::v-deep .menu-question {
    padding: 10px;
    // display: flex;
    // justify-content: space-between;
  }

  ::v-deep .question-item {
    width: 46% !important;
    font-size: 9px;
    display: inline-block;
    margin-right: 4%;
    height: 25px;
    line-height: 25px;
  }

  .left,
  .middle,
  .right {
    height: 90%;
  }

  .left,
  .right {
    width: 190px;
    box-shadow: 0px 4px 10px 0px #f2f2f2;
    border-radius: 4px 4px 4px 4px;
    background: #fff;
  }

  .right {
    overflow: auto;
  }

  .middle {
    margin: 0 12px;
    flex: 1;
  }

  .middle-top {
    margin-bottom: 26px;
    height: 40px;

    .page-list {
      display: inline-flex;
      align-items: center;
      height: 100%;
      box-shadow: 0px 1px 4px 0px #d9d9d9;
      border-radius: 4px 4px 4px 4px;
      background: #fff;

      i {
        font-size: 14px;
        cursor: pointer;
      }
    }

    .handle {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin-left: 8px;
      cursor: pointer;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 15px;
        background: #d8d8d8;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    .page-container {
      display: flex;
      align-items: center;
      margin-left: 8px;

      .pages {
        display: flex;
        align-items: center;
      }

      .page-item {
        padding: 0 8px;
        font-size: 13px;
        cursor: pointer;

        &.active {
          color: #008755;
        }
      }

      .page-ctrl.disabled {
        color: #7e7e7e;
        cursor: not-allowed;
      }
    }

    .end {
      padding: 0 8px;
      font-size: 14px;
      color: #3d3d3d;
      cursor: pointer;
    }
  }

  .middle-bottom {
    overflow: auto;
    padding: 100px 0;
    height: calc(100% - 66px);
    background: #fff;
  }

  .middle-title,
  .middle-prompt {
    padding: 0 43px;
  }

  .middle-prompt {
    margin-bottom: 20px;
  }

  .left {
    display: flex;
  }

  .menus {
    overflow: auto;
    padding: 20px 12px;
    height: 100%;
    border-right: 1px solid #f6f6f6;

    .block {
      display: block;
      margin: 0 auto 4px;
      width: 18px;
      height: 18px;
    }

    .menu {
      margin-bottom: 20px;
      font-size: 11px;
      color: #3d3d3d;
      cursor: pointer;

      &.active {
        color: #008755;
      }
    }
  }

  .menu-view {
    overflow: auto;
    flex: 1;
  }
}

.title-ctrl {
  margin-bottom: 60px;

  ::v-deep {
    input {
      padding-top: 15px;
      padding-bottom: 15px;
      height: auto;
      text-align: center;
      font-size: 30px;
      font-weight: 500;
      color: #3d3d3d;
    }
  }
}

.common-input {
  ::v-deep {

    input,
    textarea {
      padding-left: 0;
      border-color: transparent;

      &:hover {
        border-color: #008755;
        border-style: dashed;
      }

      &:focus {
        border-color: #008755;
        border-style: solid;
      }
    }
  }
}

::v-deep {
  .el-tabs__item {

    &.is-active,
    &:hover {
      color: #008755;
    }
  }

  .el-tabs__active-bar {
    background-color: #008755;
    // left: 62px;
  }

  .el-tabs__nav-scroll {
    margin-left: 60px;
  }

  element.style {
    width: 80px;
  }

  .sort {
    margin-right: 10px;
  }
}
</style>
