<template>
    <div
      :class="{ active: active === val.id }"
      class="scorer"
      @click="$emit('focus-view', val)"
    >
      <div class="head">
        <div class="tag" :id="`tag-${val.id}`">
          <slot name="sort">
            
          </slot>
          {{ val.type === 2 ? "评分人" : "被评分人" }}
        </div>
        <div class="question-handle">
          <!-- <el-button type="primary" @click="visible = true">导入</el-button> -->
          <img
            class="question-img"
            src="../../assets/编组 34.svg"
            @click="visible = true"
            alt=""
          />
          <i class="el-icon-delete" @click="$emit('del-question', val)"></i>
        </div>
      </div>
      <div class="query">
        <el-input
          placeholder="输入关键字进行过滤"
          v-model="filterText"
          size="mini"
        >
        </el-input>
      </div>
      <div v-if="selectList.length" class="tags">
        <el-tag
          v-for="tag in selectList"
          :key="tag.uid"
          class="select"
          closable
          @close="tagClose(tag)"
        >
          {{ tag.nickname }}
        </el-tag>
      </div>
      <el-tree
        :data="list"
        :props="{ children: 'child' }"
        :filter-node-method="filterNode"
        :default-checked-keys="val.subjectJson?.user"
        show-checkbox
        node-key="username"
        ref="tree"
        class="tree"
        @check="checkChange"
      >
        <span class="custom-tree-node" slot-scope="{ data }">
          <span>
            <template v-if="data.type === 'user'">
              <i class="el-icon-user-solid"></i>
              {{ data.nickname }}
            </template>
            <template>
              {{ data.departmentName }}
            </template>
          </span>
        </span>
      </el-tree>
      <upload :visible.sync="visible" @list="listTree" />
    </div>
</template>

<script>
import twoWay from "@/utils/two-way";
import upload from "../upload";
// import draggable from "vuedraggable";

export default {
  name: "scorer",
  mixins: [twoWay],
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    active: {
      type: Number,
      default: 0,
    },
  },
  components: {
    upload,
    // draggable,
  },
  data() {
    return {
      filterText: "",
      selectList: [],
      visible: false,
      target: [],
    };
  },
  inject: ["reload"],
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    list() {
      setTimeout(() => {
        this.selectList = this.$refs['tree'].getCheckedNodes(true);

        this.$emit('change', this.val, this.selectList);
      }, 100)
    },
    // target(){
    //   this.$refs.tree.setCheckedKeys([])
    //   console.log(this.val.subjectJson.user,'this.val.subjectJson.user');
    // }
  },
  methods: {
    listTree() {
      this.reload();
    },
    checkChange() {
      const nodes = this.$refs.tree.getCheckedNodes(true);
      this.selectList = nodes;
      this.val.subjectJson.user = nodes.map(item => item.username);
      this.$emit('change', this.val, this.selectList);
    },
    filterNode(value, data) {
      if (!value) return true;
      const str = data.type === "user" ? data.nickname : data.departmentName;
      return str.indexOf(value) !== -1;
    },
    tagClose(tag) {
      const index = this.selectList.findIndex(
        (i) => i.username === tag.username
      );
      this.selectList.splice(index, 1);
      this.$nextTick(() => {
        const tree = this.$refs.tree;
        const keys = this.selectList.map((i) => i.username);
        this.val.subjectJson.user = keys;
        this.$emit('change', this.val, this.selectList);
        tree.setCheckedKeys(this.selectList);
      });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.tags {
  margin-bottom: 15px;

  .select {
    margin-right: 3px;
    margin-bottom: 1px;
  }
}
.tag {
  width: 93px;
  height: 30px;
  background: #f7f7f7;
  border-radius: 3px 3px 3px 3px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  color: #3d3d3d;
}
.scorer {
  padding: 10px 43px;
  transition: 0.3s;
  margin-bottom: 20px;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &:hover,
  &.active {
    .question-handle {
      display: flex;
      align-items: center;
      i {
        cursor: pointer;
        margin-left: 10px;
      }
    }

    // transform: scale(1.03);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    transition: 0.3s;
  }
  .question-handle {
    display: none;
    font-size: 18px;

    i {
      cursor: pointer;
    }
    .question-img {
      cursor: pointer;
    }
  }
}
.tree {
  font-size: 10px;
}

.query,
.tag {
  margin-bottom: 8px;
}
</style>
