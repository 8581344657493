<template>
  <div class="ctrl-global">
    <div class="ctrl-item">
      <div class="ctrl-title">题目
      </div>
      <div style="margin-bottom:10px" v-for="(check,index) in checkList" :key="index">
        <div class="setChoose">{{ check.value }}的设置</div>
        <div class="blank" v-if="type === 7" >
          设置排他选项
          <el-switch
            style="margin: 0 0 10px 0"
            v-model="check.exclusive"
            active-color="##3f9eff"
            inactive-color="##dddfe6"
            @change="switchChange(check)"
          >
          </el-switch>
        </div>
        <div class="blank">
          在选项后添加填空
          <el-switch
            style="margin-left: 60px"
            v-model="check.disabled"
            active-color="##3f9eff"
            inactive-color="##dddfe6"
          >
          </el-switch>
        </div>
        <div class="text">
          文本格式
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="text">
          最多填写
          <el-input
            v-model="limit"
            :placeholder="placeholder"
            style="width: 200px"
            disabled
          ></el-input>
        </div>
        <div class="requiredBlank">
          <el-checkbox v-model="checked">填空必填</el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      blank: true,
      exclusive: true,
      value: "",
      limit: "",
      checked: true,
      placeholder: "不限格式",
      options: [
        {
          value: "1",
          label: "不限格式",
        },
        {
          value: "2",
          label: "数字",
        },
        {
          value: "3",
          label: "日期",
        },
        {
          value: "4",
          label: "电子邮件",
        },
        {
          value: "5",
          label: "中文",
        },
        {
          value: "6",
          label: "英文",
        },
        {
          value: "7",
          label: "网址",
        },
        {
          value: "8",
          label: "身份证号",
        },
        {
          value: "9",
          label: "电话号",
        },
      ],
      typeMap:{
        matrix: 1,
        scorer: 2,
        markScorer: 3,
        text: 4,
        options: 5,
        check: 6,
        checkbox: 7,
        date: 8,
        img: 9,
        tree: 10,
        matrixRadio: 11,
        matrixMultipleSelection: 12,
      },
      type:0,
      checkList: [],
      switchData:{}
    };
  },
  methods: {
    getCheckList(val) {
      this.type = val.type;
      this.checkList = val.subjectJson.radioList;
    },
    getCheckBoxList(val) {
      this.type = val.type;
      this.checkList = val.subjectJson.list;
    },
    switchChange(val){
      this.checkList.forEach(item => {
        if(item.value == val.value && this.switchData !== val) {
          this.switchData = item;
          item.exclusive = true;
        }else{
          item.exclusive = false;
        }
      })
    }
  },
  watch: {
    //下拉框最多填写限制
    value(value) {
      if (value === "8") {
        this.placeholder = "18位";
      } else if (value === "9") {
        this.placeholder = "11位";
      } else {
        this.placeholder = "不限格式";
      }
    },
  },
  mounted() {
    this.$bus.$on("getCheckList", this.getCheckList);
    this.$bus.$on("getCheckBoxList", this.getCheckBoxList);
  },
};
</script>

<style lang="scss" scoped>
.ctrl-global {
  padding: 19px 12px;

  .ctrl-item {
    margin-bottom: 26px;
  }
  .blank {
    font-size: 9px;
    display: flex;
    justify-content: space-between;
  }
  .ctrl-title {
    margin-bottom: 16px;
    font-size: 11px;
    font-weight: 500;
    color: #3d3d3d;
  }

  .right {
    display: flex;
    align-items: center;
  }

  .set-btn {
    color: #008755;
  }
  .setChoose {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .text {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 9px;
  }
  .requiredBlank {
    margin-top: 10px;
    font-size: 9px;
  }
}
</style>
