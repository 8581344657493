<template>
  <div class="preview">
    <div class="preview_item">
      <preview1 :questionModel="questionModel"></preview1>
    </div>
    <div class="preview_item">
      <preview2 :questionModel="questionModel" :subjectList="subjectList" :selectList="selectList"></preview2>
    </div>
    <div class="preview_item">
      <preview3></preview3>
    </div>
  </div>
</template>

<script>
import preview1 from './preview1.vue'
import preview2 from './preview2.vue'
import preview3 from './preview3.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    preview1,
    preview2,
    preview3,
  },
  props: {
    subjectList: {
      default: {}
    },
    questionModel: {
      default: {}
    },
    selectList: {
      default: []
    },
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.preview {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  .preview_item {
    overflow-y: scroll;
    /* 隐藏滚动条 */
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    width: 32%;
    height: 400px;
    border: 1px solid #ccc;
  }
}
</style>
