<template>
  <div
    :class="{ active: active === val.id }"
    class="matrix-scale"
    @click="divClick"
  >
    <div class="head">
      <div class="tag" :id="`tag-${val.id}`">
        <slot name="sort"> </slot>    
        单选
      </div>
      <div class="question-handle">
        <i class="el-icon-delete" @click="$emit('del-question', val)"></i>
      </div>
    </div>
    <div class="input-container">
      <div class="input-item">
        <el-input
          v-model="val.title"
          placeholder="请输入题目标题"
          class="common-input"
          @change="$emit('change', val)"
        />
      </div>
      <div class="input-item">
        <el-input
          v-model="subjectInfo.value"
          placeholder="请输入题目说明(选填)"
          class="common-input"
          @change="$emit('change', val)"
        />
      </div>
      <div
        contenteditable
        class="input-item radio-class"
        v-for="(item, index) in subjectInfo.radioList"
        :key="index"
        @click.stop="selectItem(index)"
        :style="getStyle(index)"
      >
        <el-radio v-model="subjectInfo.radio" :label="item.value"> </el-radio>
        <div contenteditable @input="listLabelChange($event,index)" class="editLabel">
          {{ item.value }}
        </div>
        <div class="text"></div>
        <div class="delete" @click="deleteRadio(index)">
          <i class="el-icon-close"></i>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="handle">
      <el-button type="text" class="handle-btn">
        <span @click="addRow">
          <i class="el-icon-circle-plus-outline"></i>
          添加行
        </span>
        <span class="rightLine"></span>
        <span @click="editAll">
          <i class="el-icon-folder-add"></i>
          批量编辑
        </span>
      </el-button>

      <el-drawer
        title="批量编辑"
        :append-to-body="true"
        custom-class="customClass"
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleClose"
      >
        <div
          class="allInputRef"
          v-for="(item, index) in subjectInfo.radioList"
          :key="index"
          ref="allInputRef"
        >
          <div
            class="all-input"
            contenteditable="true"
            placeholder=""
            @keydown="enter"
            @blur="setValue(index, 'value', $event.target.innerText)"
          >
            {{ item.value }}
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import twoWay from "@/utils/two-way";
// import draggable from "vuedraggable";

export default {
  name: "Radio",
  mixins: [twoWay],
  props: {
    global: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Number,
      default: 0,
    },
  },
  components: {
    // draggable
  },
  data() {
    return {
      drawer: false,
      direction: "rtl",
    };
  },
  computed: {
    subjectInfo() {
      return this.val.subjectJson;
    },
  },
  methods: {
    addRow() {
      this.subjectInfo.radioList.push({
        value: "选项",
        text: "",
        disabled: true,
      });
    },
    setValue(index, target, value) {
      this.subjectInfo.radioList[index][target] = value;
    },
    selectItem(index) {
      this.subjectInfo.selectedIndex = index;
      this.divClick();
    },
    getStyle(index) {
      return {
        border:
          index === this.subjectInfo.selectedIndex
            ? "1px solid #3f9eff"
            : "none",
      };
    },
    divClick() {
      this.$emit("focus-view", this.val);
      this.$bus.$emit("getCheckList", this.val);
    },
    deleteRadio(selectedIndex) {
      if (this.subjectInfo.radioList.length <= 1) {
        this.$message.error("最少有一项");
      } else {
        this.subjectInfo.radioList.splice(selectedIndex, 1);
      }
    },
    editAll() {
      this.drawer = true;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },

    enter() {
      console.log(11);
    },
    listLabelChange(e, index) {
      const str = e.target.innerText;
      console.log(str, index);
      this.subjectInfo.radioList[index].value = str;
    },
  },
};
</script>

<style lang="scss" scoped>
.matrix-scale {
  padding: 10px 43px;
  transition: 0.3s;
  margin-bottom: 20px;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:hover,
  &.active {
    .question-handle {
      display: block;
    }

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  ::v-deep(.radioTitle::after) {
    content: "111";
    // width: 100px;
    // height: 1px;
    border-bottom: 1px solid red;
  }

  .tag {
    display: inline-block;
    margin-top: 18px;
    margin-bottom: 18px;
    padding: 2px 7px;
    line-height: 27px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 2px 2px 2px 2px;
    font-size: 14px;
    color: #3d3d3d;
  }

  ::v-deep(.customClass) {
    font-size: 16px !important;
  }

  .radio-active {
    border: 1px solid #3f9eff;
  }

  .input-item {
    margin-bottom: 15px;
    display: flex;
    height: 20px;
    position: relative;

    ::v-deep(.el-input__inner) {
      border: none;
      margin: 0;
      padding: 0;
    }

    ::v-deep(.el-radio__label) {
      display: none;
    }

    .editLabel {
      height: 100%;
      font-size: 16px;
      line-height: 40px;
      margin-right: 30px;
    }

    .text {
      width: 100px;
      height: 60%;
      border-bottom: 1px solid black;
    }

    ::v-deep(.el-input) {
      min-width: 28px;
    }

    .delete {
      position: absolute;
      right: 0;
    }
  }

  .delete {
    cursor: pointer;
  }

  .radio-class {
    display: flex;
    align-items: center;
  }

  .divider {
    width: 100%;
    border-top: 1px solid #d8d8d8;
  }

  .handle {
    padding-top: 11px;
  }

  .handle-btn {
    color: #3d3d3d;

    i {
      padding: 0 6px;
    }
  }

  .rightLine {
    border-right: 1px solid #d8d8d8;
  }

  ::v-deep(.all-input) {
    width: 300px;
    height: 10px;
    margin-left: 10px !important;
    font-size: 10px !important;
  }

  .common-input {
    ::v-deep {
      input,
      textarea {
        padding-left: 0;
        border-color: transparent;

        &:hover {
          border-color: #008755;
          border-style: dashed;
        }

        &:focus {
          border-color: #008755;
          border-style: solid;
        }
      }

      .el-input-group__prepend {
        border: none;
        background: transparent;
      }
    }
  }
}
</style>
