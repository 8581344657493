<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    fill="none"
    version="1.1"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <defs>
      <clipPath id="master_svg0_22_12842">
        <rect
          x="0"
          y="0"
          width="18"
          height="18"
          rx="0"
        />
      </clipPath>
    </defs>
    <g style="mix-blend-mode:passthrough">
      <g
        style="mix-blend-mode:passthrough"
        clip-path="url(#master_svg0_22_12842)"
      >
        <g style="mix-blend-mode:passthrough">
          <path
            d="M1.5,2.25C1.5,1.835786,1.835786,1.5,2.25,1.5C2.25,1.5,7.5,1.5,7.5,1.5C7.91421,1.5,8.25,1.835786,8.25,2.25C8.25,2.25,8.25,7.5,8.25,7.5C8.25,7.91421,7.91421,8.25,7.5,8.25C7.5,8.25,2.25,8.25,2.25,8.25C1.835786,8.25,1.5,7.91421,1.5,7.5C1.5,7.5,1.5,2.25,1.5,2.25C1.5,2.25,1.5,2.25,1.5,2.25ZM3,3C3,3,3,6.75,3,6.75C3,6.75,6.75,6.75,6.75,6.75C6.75,6.75,6.75,3,6.75,3C6.75,3,3,3,3,3C3,3,3,3,3,3ZM9.75,2.25C9.75,1.835786,10.08577,1.5,10.5,1.5C10.5,1.5,15.75,1.5,15.75,1.5C16.1642,1.5,16.5,1.835786,16.5,2.25C16.5,2.25,16.5,7.5,16.5,7.5C16.5,7.91421,16.1642,8.25,15.75,8.25C15.75,8.25,10.5,8.25,10.5,8.25C10.08577,8.25,9.75,7.91421,9.75,7.5C9.75,7.5,9.75,2.25,9.75,2.25C9.75,2.25,9.75,2.25,9.75,2.25ZM11.25,3C11.25,3,11.25,6.75,11.25,6.75C11.25,6.75,15,6.75,15,6.75C15,6.75,15,3,15,3C15,3,11.25,3,11.25,3C11.25,3,11.25,3,11.25,3ZM1.5,10.5C1.5,10.08577,1.835786,9.75,2.25,9.75C2.25,9.75,7.5,9.75,7.5,9.75C7.91421,9.75,8.25,10.08577,8.25,10.5C8.25,10.5,8.25,15.75,8.25,15.75C8.25,16.1642,7.91421,16.5,7.5,16.5C7.5,16.5,2.25,16.5,2.25,16.5C1.835786,16.5,1.5,16.1642,1.5,15.75C1.5,15.75,1.5,10.5,1.5,10.5C1.5,10.5,1.5,10.5,1.5,10.5ZM3,11.25C3,11.25,3,15,3,15C3,15,6.75,15,6.75,15C6.75,15,6.75,11.25,6.75,11.25C6.75,11.25,3,11.25,3,11.25C3,11.25,3,11.25,3,11.25ZM9.75,10.5C9.75,10.08577,10.08577,9.75,10.5,9.75C10.5,9.75,15.75,9.75,15.75,9.75C16.1642,9.75,16.5,10.08577,16.5,10.5C16.5,10.5,16.5,15.75,16.5,15.75C16.5,16.1642,16.1642,16.5,15.75,16.5C15.75,16.5,10.5,16.5,10.5,16.5C10.08577,16.5,9.75,16.1642,9.75,15.75C9.75,15.75,9.75,10.5,9.75,10.5C9.75,10.5,9.75,10.5,9.75,10.5ZM11.25,11.25C11.25,11.25,11.25,15,11.25,15C11.25,15,15,15,15,15C15,15,15,11.25,15,11.25C15,11.25,11.25,11.25,11.25,11.25C11.25,11.25,11.25,11.25,11.25,11.25Z"
            fill-rule="evenodd"
            :fill="fill"
            fill-opacity="1"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['active'],
  computed: {
    fill() {
      return this.active ? '#008755' : '#3D3D3D';
    }
  }
}
</script>

<style>
</style>