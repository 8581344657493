<template>
  <div class="ctrl-question">
    <div class="ctrl-item">
      <div class="ctrl-title">题目设置</div>
      <div class="ctrl-content">
        <div class="set-item">
          <el-select v-model="val.data.type" size="mini" @change="$emit('upDateComponentType',val,val.data.type)">
            <el-option :value="1" label="矩阵量表" />
            <el-option :value="2" label="评分人" />
            <el-option :value="3" label="被评分人" />
            <el-option :value="4" label="多行文本" />
            <el-option :value="5" label="下拉框" />
            <el-option :value="6" label="单选题" />
            <el-option :value="7" label="多选题" />
            <el-option :value="8" label="日期" />
            <el-option :value="9" label="图片" />
            <el-option :value="10" label="树" />
            <el-option :value="11" label="矩形单选" />
            <el-option :value="12" label="矩阵多选" />
          </el-select>
        </div>
        <div class="set-item">
          <el-checkbox
            v-model="val.data.required"
            @change="$emit('change', val)"
          >
            必答题
          </el-checkbox>
        </div>
        <div class="set-item">
          <el-checkbox
            v-model="val.data.sensitiveInformationCollection"
            @change="$emit('change', val)"
          >
            敏感信息收集题
          </el-checkbox>
          <el-tooltip content="提示提示">
            <i class="el-icon-question"></i>
          </el-tooltip>
        </div>
        <div v-if="[2, 3].includes(val.data.type)" class="set-item">
          <el-checkbox
            v-model="val.data.subjectJson.bindScorer"
            @change="$emit('change', val)"
          >
            是否绑定被评分人
          </el-checkbox>
          <el-tooltip
            content="此处要绑定评分人需要在首页对试卷整体进行设置，设置评分人，然后在这个地方选择评分人，每次更换评分人，都可以对被评分人重新进行多选操作。操作完成，点击确定及锁定评分任何被评分人的关系，在评分时，检测到评分人后，问卷中只可展示该评分人本次可以进行的评分设置。评分人和被评分人存入部门字段，答题时如果需要选择部门的话，在答题处可以根据部门读取出来评分人和被评分人。"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="ctrl-item">
      <div class="ctrl-content">
        <template v-if="val.data.type === 1">
          <div class="set-item flex">
            两级文案
            <el-select
              v-model="val.data.subjectJson.polarization"
              size="mini"
              class="set-ctrl"
              @change="polarizationChange"
            >
              <el-option label="满意度" :value="1" />
              <el-option label="认可度" :value="2" />
              <el-option label="自定义" :value="3" />
            </el-select>
          </div>
          <div v-if="val.data.subjectJson.polarization === 3" class="set-item">
            <div class="input-container">
              <el-input
                v-model="val.data.subjectJson.polarizationStart"
                placeholder="开始"
                size="mini"
                @change="$emit('change', val)"
              />
            </div>
            <div class="input-container">
              <el-input
                v-model="val.data.subjectJson.polarizationEnd"
                placeholder="结束"
                size="mini"
                @change="$emit('change', val)"
              />
            </div>
          </div>
          <div class="set-item flex">
            量表维度
            <el-select
              v-model="val.data.subjectJson.dimensionality"
              size="mini"
              class="set-ctrl"
              @change="$emit('change', val)"
            >
              <el-option
                v-for="i in 11"
                :key="i"
                :label="i"
                :value="i"
              ></el-option>
            </el-select>
          </div>
          <div class="set-item flex">
            起始数值
            <el-input-number
              v-model="val.data.subjectJson.startNum"
              :step="1"
              :step-strictly="true"
              size="mini"
              class="set-ctrl"
              controls-position="right"
              @change="$emit('change', val)"
            ></el-input-number>
          </div>
          <div class="set-item flex">
            排序方式
            <el-radio-group
              size="mini"
              v-model="val.data.subjectJson.sort"
              @change="$emit('change', val)"
            >
              <el-radio-button label="ascending">正序</el-radio-button>
              <el-radio-button label="descending">倒叙</el-radio-button>
            </el-radio-group>
          </div>
        </template>
        <template v-if="[2, 3].includes(val.data.type)">
          <div class="set-item">
            <div class="whoans">谁可以填答</div>
            <el-select v-model="val.data.subjectJson.fillType" size="mini">
              <el-option :value="1" label="所有人" />
              <el-option :value="2" label="联系人" />
              <el-option :value="3" label="录入名单" />
              <el-option :value="4" label="选择评分人" />
            </el-select>
          </div>
          <div v-if="val.data.type === 2" class="set-item flex">
            评分人
            <el-select
              v-model="val.data.subjectJson.fillScorer"
              size="mini"
              @change="changeScorer"
            >
              <el-option
                v-for="option in scorerList"
                :key="option.username"
                :value="option.username"
                :label="option.nickname"
              />
            </el-select>
          </div>
          <div v-if="val.data.type === 2" class="set-item flex">
            被评分人
            <el-select
              v-model="
                val.data.subjectJson.scorerRecord[
                  val.data.subjectJson.fillScorer
                ]
              "
              :multiple="true"
              :disabled="!val.data.subjectJson.fillScorer"
              size="mini"
              @change="changeMarkScorer"
            >
              <el-option
                v-for="option in markScorerList"
                :key="option.username"
                :value="option.username"
                :label="option.nickname"
                :disabled="option.username === val.data.subjectJson.fillScorer"
              />
            </el-select>
          </div>
          <div class="set-item">
            <el-checkbox
              v-model="val.data.subjectJson.needDept"
              @change="$emit('change', val)"
            >
              答题时是否需要选择部门
            </el-checkbox>
          </div>
        </template>
        <template v-if="val.data.type === 5">

        </template>
      </div>
    </div>
    <!-- <div class="ctrl-item">
      <div class="ctrl-title">逻辑设置</div>
      <div class="ctrl-content">
      </div>
    </div> -->
  </div>
</template>

<script>
import twoWay from "../../utils/two-way";
const polarizationMap = {
  1: {
    s: "非常不满意",
    e: "非常满意",
  },
  2: {
    s: "非常不认可",
    e: "非常认可",
  },
};

export default {
  name: "ctrl-question",
  mixins: [twoWay],
  props: {
    scorerList: { type: Array, default: () => [] },
    markScorerList: { type: Array, default: () => [] },
  },
  data() {
    return {
      sort: "",
    };
  },
  watch: {
    // 'val.data.subjectJson.user'(v) {
    //   console.log(v);
    // }
  },
  methods: {
    changeScorer(v) {
      const record = this.val.data.subjectJson.scorerRecord;
      if (!record[v]) {
        this.$set(record, v, []);
      }
      this.$emit("change", this.val);
    },
    changeMarkScorer(v) {
      const subject = this.val.data.subjectJson;
      subject.scorerRecord[subject.fillScorer] = v;
      this.$emit("change", this.val);
    },
    polarizationChange(v) {
      this.$emit("change", this.val);
      const subject = this.val.data.subjectJson;
      if (v !== 3) {
        subject.polarizationStart = polarizationMap[v].s;
        subject.polarizationEnd = polarizationMap[v].e;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ctrl-question {
  padding: 19px 12px;

  .ctrl-item {
    margin-bottom: 26px;
  }
  .ctrl-title {
    margin-bottom: 16px;
    font-size: 11px;
    font-weight: 500;
    color: #3d3d3d;
  }
  .ctrl-content {
    .switch-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      font-size: 13px;
      color: #3d3d3d;
    }
  }

  .set-item {
    margin-bottom: 8px;
    font-size: 10px;
    color: #3d3d3d;

    .whoans {
      padding-bottom: 7px;
    }

    &.flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;

      ::v-deep {
        .el-select {
          width: 70%;
        }

        .el-radio-group {
          width: 70%;

          .el-radio-button {
            width: 50%;
          }

          .el-radio-button__inner {
            width: 100%;
          }
        }
      }
    }
  }

  .input-container {
    margin-bottom: 8px;
  }
  .set-ctrl {
    width: 70%;
  }

  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
}
</style>
