<template>
  <div class="menu-question">
    <div v-for="menu in list" :key="menu.id">
      <div class="question-title">{{ menu.title }}</div>
      <div class="question-list" v-for="chil in menu.chilList" :key="chil.id">
        <draggable
          v-model="menu.chilList"
          animation="1000"
          force-fallback="true"
          v-bind="{group:{name: 'data',pull:'clone'}}" 
          @start="drag = true"
          @end="$emit('add', chil.type)"
          chosen-class="chosen"
        >
          <!-- @click="$emit('add', chil.type)" -->
          <div :key="chil.title" v-if="chil.disabled" class="question-item" @click="$emit('add', chil.type)">
            {{ chil.title }}
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      drag: false,
      list: [
        {
          id: 1,
          group: "people",
          title: "评分人",
          chilList: [
            {
              id: 2,
              data:{},
              index:2,
              group: "people",
              title: "评分人",
              type: "scorer",
              disabled: this.$route.query.pos !== 2, //外部问卷隐藏评分人
            },
            {
              id: 3,
              data:{},
              index:3,
              group: "people",
              title: "被评分人",
              type: "markScorer",
              disabled: true,
            },
          ],
        },
        {
          id: 4,
          group: "people",
          title: "矩阵",
          chilList: [
            {
              id: 5,
              data:{},
              index:5,
              group: "people",
              title: "矩阵量表",
              type: "matrix",
              disabled: true,
            },
            {
              id:11,
              data:{},
              index:6,
              group: "people",
              title: "矩阵单选",
              type: "matrixRadio",
              disabled: true,
            },
            {
              id:12,
              data:{},
              index:7,
              group: "people",
              title: "矩阵多选",
              type: "matrixMultipleSelection",
              disabled: true,
            }
          ],
        },
        {
          id: 6,
          group: "people",
          title: "文本",
          chilList: [
            {
              id: 7,
              data:{},
              index:7,
              group: "people",
              title: "多行文本",
              type: "text",
              disabled: true,
            },
          ],
        },
        {
          id: 7,
          group: "people",
          title: "选择题",
          chilList: [
            {
              id: 8,
              data:{},
              index:8,
              group: "people",
              title: "单选",
              type: "check",
              disabled: true,
            },
            {
              id: 9,
              data:{},
              index:9,
              group: "people",
              title: "多选",
              type: "checkbox",
              disabled: true,
            },
            {
              id: 10,
              data:{},
              index:10,
              group: "people",
              title: "下拉",
              type: "options",
              disabled: true,
            },
          ],
        },
      ],
    };
  },
  methods:{
    cloneItem:function(val){
      // console.log(val);
   // 深复制一个节点
   return JSON.parse(JSON.stringify(val))
  },
  }
};
</script>

<style lang="scss" scoped>
.question-title {
  margin-bottom: 9px;
  font-size: 14px;
  color: #3d3d3d;
}

.question-item {
  margin-bottom: 8px;
  width: 50%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 4px 4px 4px 4px;
  font-size: 13px;
  background: #f7f7f7;
  color: #3d3d3d;
  cursor: pointer;
}
.chosen {
  border: solid 2px #3f9eff !important;
}
</style>
