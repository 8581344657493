<template>
  <div
    :class="{ active: active === val.id }"
    class="matrix-scale"
    @click="$emit('focus-view', val)"
  >
    <div class="head">
      <div class="tag" :id="`tag-${val.id}`">
        <slot name="sort"> </slot>
        矩阵量表
      </div>
      <div class="question-handle">
        <i class="el-icon-delete" @click="$emit('del-question', val)"></i>
      </div>
    </div>
    <div class="question-title">
      <el-input
        v-model="val.title"
        :class="{
          'is-require': val.required,
          'is-show-num': global.displayTitleNumber,
        }"
        class="common-input"
        @change="$emit('change', val)"
        placeholder="请输入"
      />
      <div v-if="val.required || global.displayTitleNumber" class="before">
        <span v-if="val.required" class="question-require">*</span>
        <span v-if="global.displayTitleNumber" class="question-num">1</span>
      </div>
    </div>
    <div class="question-prompt">
      <el-input
        class="common-input"
        v-model="val.name"
        @change="$emit('change', val)"
        placeholder="请输入"
        type="textarea"
      />
    </div>
    <div class="polarization">
      <div class="polarization-tag">
        {{ val.subjectJson?.polarizationStart }}
      </div>
      <div class="polarization-tag">
        {{ val.subjectJson?.polarizationEnd }}
      </div>
    </div>
    <div class="lines">
      <div
        v-for="(line, index) in val.subjectJson?.lines"
        :key="index"
        class="line"
      >
        <div class="line-title">
          <el-form :model="line"  :rules="rules" :inline="true">
            <el-form-item label="行标题：" prop="title">
              <el-input
                class="common-input"
                v-model="line.title"
                @change="$emit('change', val)"
                placeholder="请输入"
              >
                <!-- <template slot="prepend">行标题：</template> -->
              </el-input>
            </el-form-item>
            <el-form-item label="维度：" prop="category">
              <el-input
                class="common-input"
                v-model="line.category"
                @change="$emit('change', val)"
                placeholder="请输入"
              >
                <!-- <template slot="prepend">维度：</template> -->
              </el-input>
            </el-form-item>
          </el-form>
          <!-- <div class="ctrl-item title">
            <el-input
              class="common-input"
              v-model="line.title"
              @change="$emit('change', val)"
              placeholder="请输入"
              :rules="[{ required: true, message: '请输入' }]"
            >
              <template slot="prepend">行标题：</template>
            </el-input>
          </div>
          <div class="ctrl-item category">
            <el-input
              class="common-input"
              v-model="line.category"
              @change="$emit('change', val)"
              placeholder="请输入"
              :rules="[{ required: true, message: '请输入' }]"
            >
              <template slot="prepend">维度：</template>
            </el-input>
          </div> -->
          <i class="el-icon-delete delete-line" @click="deleteLine(index)"></i>
        </div>
        <div class="line-content">
          <div v-for="i in numList" :key="i" class="box">
            {{ i + val.subjectJson.startNum - 1 }}
          </div>
        </div>
      </div>
    </div>
    <div class="handle">
      <el-button type="text" class="handle-btn" @click="addLine">
        <i class="el-icon-circle-plus-outline"></i>
        添加行
      </el-button>
    </div>
  </div>
</template>

<script>
import twoWay from "@/utils/two-way";

export default {
  name: "scale",
  mixins: [twoWay],
  props: {
    global: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      rules: {
        title: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        category: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
      },
    };
  },
  components: {},
  computed: {
    numList() {
      const subject = this.val.subjectJson;
      let arr = [];
      if (subject.sort === "ascending") {
        for (let index = 0; index < subject.dimensionality; index++) {
          arr.push(index + 1);
        }
      } else {
        for (let index = subject.dimensionality; index > 0; index--) {
          arr.push(index);
        }
      }
      return arr;
    },
  },
  methods: {
    addLine() {
      this.val.subjectJson.lines.push({
        title: "",
        category: "",
        value: 0,
      });
      this.$emit("change", this.val);
    },
    deleteLine(index) {
      this.val.subjectJson.lines.splice(index, 1);
      this.$emit("change", this.val);
    },
  },
};
</script>

<style lang="scss" scoped>
.matrix-scale {
  padding: 10px 43px;
  transition: 0.3s;
  margin-bottom: 20px;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &:hover,
  &.active {
    .question-handle {
      display: block;
    }

    // transform: scale(1.03);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    // transition: 0.3s;
  }
  .question-handle {
    display: none;
    font-size: 18px;

    i {
      cursor: pointer;
    }
  }
  .is-show-num,
  .is-require {
    padding-left: 40px;
  }
  // .tag,
  // .polarization,
  // .lines,
  // .handle {
  //   // margin-left: 15px;
  // }
  .tag {
    display: inline-block;
    margin-top: 18px;
    margin-bottom: 18px;
    padding: 2px 7px;
    line-height: 27px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 2px 2px 2px 2px;
    font-size: 14px;
    color: #3d3d3d;
  }

  .question-title {
    position: relative;
    margin-bottom: 26px;

    .before {
      display: flex;
      align-items: center;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .question-require {
      margin-right: 5px;
      font-size: 10px;
      color: #fa5151;
    }
    .question-num {
      font-size: 14px;
    }
  }
  .question-prompt {
    margin-bottom: 30px;
  }

  .polarization {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-tag {
      padding: 3px 5px;
      font-size: 14px;
      color: #3d3d3d;
      background: #f2f2f2;
    }
  }

  .lines {
    margin-bottom: 13px;
    .line {
      margin-top: 26px;
    }

    .line-title {
      position: relative;
      display: flex;
      margin-bottom: 16px;

      .el-form{
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      .el-form-item{
        display: flex;
      }

      .delete-line {
        position: absolute;
        font-size: 16px;
        top: 50%;
        right: -32px;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }

    .ctrl-item {
      &.title {
        width: 70%;
      }

      &.category {
        width: 30%;
      }
    }

    .line-content {
      display: flex;

      .box {
        position: relative;
        flex: 1;
        height: 50px;
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        background: #e5e5e5;
        border-radius: 2px 2px 2px 2px;
        color: #3d3d3d;

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          width: 1px;
          height: 100%;
          background: #fff;
        }
      }
    }
  }

  .handle {
    padding-top: 11px;
    border-top: 1px solid #d8d8d8;
  }

  .handle-btn {
    color: #3d3d3d;
  }

  .common-input {
    ::v-deep {
      input,
      textarea {
        padding-left: 0;
        border-color: transparent;

        &:hover {
          border-color: #008755;
          border-style: dashed;
        }

        &:focus {
          border-color: #008755;
          border-style: solid;
        }
      }

      .el-input-group__prepend {
        border: none;
        background: transparent;
      }
    }
  }
}
</style>
