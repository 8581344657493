<template>
  <div
    :class="{ active: active === val.id }"
    class="matrix-scale"
    @click="divClick"
  >
    <div class="head">
      <div class="tag" :id="`tag-${val.id}`">
        <slot name="sort"> </slot>
        下拉
      </div>
      <div class="question-handle">
        <i class="el-icon-delete" @click="$emit('del-question', val)"></i>
      </div>
    </div>
    <div class="input-container">
      <div class="input-item">
        <el-input
          v-model="val.title"
          placeholder="请输入题目标题"
          class="common-input"
          @change="$emit('change', val)"
        />
      </div>
      <div class="input-item">
        <el-input
          v-model="subjectInfo.value"
          placeholder="请输入题目说明(选填)"
          class="common-input"
          @change="$emit('change', val)"
        />
      </div>
      <div class="main">
        <el-select
          v-model="subjectInfo.selectValue"
          value-key=""
          placeholder="请选择"
          allow-create
          clearable
          filterable
          @change="selectChange"
          @clear="subjectInfo.selectValue = ''"
        >
          <el-option
            v-for="(item, index) in subjectInfo.options"
            :key="item.label"
            :label="item.label"
            :value="item.label"
          >
            <span style="float: left">{{ item.label }}</span>
            <span
              style="float: right; color: #8492a6; font-size: 13px"
              @click="remove(index)"
            >
              <i class="el-icon-close"></i>
            </span>
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="divider"></div>
    <div class="handle">
      <el-button type="text" class="handle-btn">
        <span @click="editAll">
          <i class="el-icon-folder-add"></i>
          批量编辑
        </span>
      </el-button>
    </div>

    <el-drawer
      title="批量编辑"
      append-to-body
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <div v-for="(item,index) in subjectInfo.options" :key="index">
        <el-input
          class="all-input"
          v-model="item.label"
          placeholder=""
          @change="allInputChange(item)"
        ></el-input>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import twoWay from "@/utils/two-way";
export default {
  name: "options",
  mixins: [twoWay],
  props: {
    global: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Number,
      default: 0,
    },
  },
  components: {
    // draggable
  },
  data() {
    return {
      drawer: false,
      direction: "rtl",
      selectValue: "",
      options: [
        {
          value: 1,
          label: "Options1",
        },
      ],
    };
  },
  computed: {
    subjectInfo() {
      return this.val.subjectJson;
    },
  },
  methods: {
    selectChange(val) {
      if (!val) return;
      let flag = this.subjectInfo.options.some((item) => item.label == val);
      let length = this.subjectInfo.options.length;
      if (!flag) {
        this.subjectInfo.options.push({ label: val, value: length });
      }
    },
    remove(index) {
      console.log(index);
      if (this.subjectInfo.options.length <= 1) {
        this.$message.error("最少保留一项");
      } else {
        this.subjectInfo.options.splice(index, 1);
        this.subjectInfo.selectValue = '';
      }
    },
    allInputChange(item){
      console.log(item);
      this.subjectInfo.selectValue = item.label
    },
    divClick() {
      this.$emit("focus-view", this.val);
    },
    editAll() {
      this.drawer = true;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.matrix-scale {
  padding: 10px 43px;
  transition: 0.3s;
  margin-bottom: 20px;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &:hover,
  &.active {
    .question-handle {
      display: block;
      z-index: 9999;
    }
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .tag {
    display: inline-block;
    margin-top: 18px;
    margin-bottom: 18px;
    padding: 2px 7px;
    line-height: 27px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 2px 2px 2px 2px;
    font-size: 14px;
    color: #3d3d3d;
  }

  .radio-class {
    align-items: center;
  }

  .radio-active {
    border: 1px solid #3f9eff;
  }

  .el-checkbox-group {
    .input-item {
      & > div {
        font-size: 16px;
        line-height: 40px;
        margin-left: -40px;
        margin-right: 10px;
      }
    }
  }

  .input-item:not(:last-child) {
    margin-bottom: 15px;
    display: flex;
    ::v-deep(.el-input__inner) {
      border: none;
      margin: 0;
      padding: 0;
    }
  }

  .input-item {
    .el-checkbox__label {
      display: flex;
      .el-input:nth-child(1) {
        width: fit-content;
      }
      .el-input:nth-child(2) {
        ::v-deep(.el-input__inner) {
          height: 20px !important;
        }
      }
    }
  }

  .main {
    margin-bottom: 10px;
  }

  .input-item:last-child {
    margin-bottom: 15px;
    display: flex;
    position: relative;

    ::v-deep(.el-input__inner) {
      border: none;
      margin: 0;
      padding: 0;
    }

    .delete {
      position: absolute;
      right: 0;
    }
  }

  .delete {
    cursor: pointer;
  }

  .radio-class:nth-last-child(1) {
    display: flex;
    align-items: center;
    .text {
      width: 100px;
      color: #3d3d3d;
      margin-bottom: 6px;
      margin-left: -20px;
      font-size: 13px;
      padding-left: 6px;
      border-top: 0px;
      border-right: 0px;
      border-left: 0px;
      outline: none;
      border-bottom: 1px solid #3d3d3d;
    }
  }

  .divider {
    width: 100%;
    border-top: 1px solid #d8d8d8;
  }

  .handle {
    padding-top: 11px;
  }

  .handle-btn {
    color: #3d3d3d;
    i {
      padding: 0 6px;
    }
  }
  .rightLine {
    border-right: 1px solid #d8d8d8;
  }

  .common-input {
    ::v-deep {
      input,
      textarea {
        padding-left: 0;
        border-color: transparent;

        &:hover {
          border-color: #008755;
          border-style: dashed;
        }

        &:focus {
          border-color: #008755;
          border-style: solid;
        }
      }

      .el-input-group__prepend {
        border: none;
        background: transparent;
      }
    }
  }
}
</style>
