<template>
  <div
    :class="{ active: active === val.id }"
    class="matrix-scale"
    @click="$emit('focus-view', val)"
  >
    <div class="head">
      <div class="tag" :id="`tag-${val.id}`">
        <slot name="sort"> </slot>
        下拉
      </div>
      <div class="question-handle">
        <i class="el-icon-delete" @click="$emit('del-question', val)"></i>
      </div>
    </div>
    <div class="input-container">
      <div class="input-item">
        <el-input
          v-model="val.title"
          placeholder="请输入题目标题"
          class="common-input"
          @change="$emit('change', val)"
        />
      </div>
      <div class="input-item">
        <el-input
          v-model="val.subjectJson.value"
          placeholder="请输入题目说明(选填)"
          class="common-input"
          @change="$emit('change', val)"
        />
      </div>
      <!-- <div
        class="input-item radio-class"
        v-for="(item, index) in radioList"
        :key="index"
        @click.stop="selectItem(index)"
        :style="getStyle(index)"
      >
        <el-radio
          v-model="radio"
          :label="item.value"
          v-if="radioList.length == index + 1"
        >
        </el-radio>
        <el-radio v-model="radio" v-else>
          <template>
            <el-input v-model="item.value"></el-input>
          </template>
        </el-radio>
        <input
          class="text"
          v-if="radioList.length == index + 1"
          v-model="item.text"
          placeholder=""
        />
        <div class="delete">
          <i class="el-icon-close"></i>
        </div>
      </div> -->
      <div>
        <el-select v-model="selectedOption" placeholder="请选择" @change="handleChange">
      <el-option v-for="option in options" :key="option.value" :label="option.label" :value="option.value"></el-option>
    </el-select>
    <el-input v-model="customOption" placeholder="自定义输入" v-if="showInput" @blur="handleBlur"></el-input>
      </div>
    </div>
    <div class="divider"></div>
    <div class="handle">
      <el-button type="text" class="handle-btn" @click="addRow">
        <i class="el-icon-circle-plus-outline"></i>
        添加行
        <span class="rightLine"></span>
        <i class="el-icon-folder-add"></i>
        批量添加
      </el-button>
    </div>
  </div>
</template>

<script>
import twoWay from "@/utils/two-way";
export default {
  name: "dropDown",
  mixins: [twoWay],
  props: {
    global: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Number,
      default: 0,
    },
  },
  components: {
    // draggable
  },
  data() {
    return {
        options: [
        { label: '选项1', value: 'option1' },
        { label: '选项2', value: 'option2' },
        { label: '选项3', value: 'option3' },
        { label: '自定义输入', value: 'custom' }
      ],
      selectedOption: '',
      customOption: '',
      showInput: false
    };
  },
  computed: {},
  methods: {
    handleChange(value) {
      if (value === 'custom') {
        this.showInput = true;
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      } else {
        this.showInput = false;
      }
    },
    handleBlur() {
      if (this.customOption) {
        this.selectedOption = this.customOption;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.matrix-scale {
  padding: 10px 43px;
  transition: 0.3s;
  margin-bottom: 20px;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &:hover,
  &.active {
    .question-handle {
      display: block;
    }

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .tag {
    display: inline-block;
    margin-top: 18px;
    margin-bottom: 18px;
    padding: 2px 7px;
    line-height: 27px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 2px 2px 2px 2px;
    font-size: 14px;
    color: #3d3d3d;
  }

  .radio-class {
    align-items: center;
  }

  .radio-active {
    border: 1px solid #3f9eff;
  }

  .input-item:not(:last-child) {
    margin-bottom: 15px;
    display: flex;

    justify-content: space-between;

    ::v-deep(.el-input__inner) {
      border: none;
      margin: 0;
      padding: 0;
    }
  }

  .input-item:last-child {
    margin-bottom: 15px;
    display: flex;
    position: relative;

    ::v-deep(.el-input__inner) {
      border: none;
      margin: 0;
      padding: 0;
    }

    .delete {
      position: absolute;
      right: 0;
    }
  }

  .delete {
    cursor: pointer;
  }

  .radio-class:nth-last-child(1) {
    display: flex;
    align-items: center;
    .text {
      width: 100px;
      color: #3d3d3d;
      margin-bottom: 6px;
      margin-left: -20px;
      font-size: 13px;
      padding-left: 6px;
      border-top: 0px;
      border-right: 0px;
      border-left: 0px;
      outline: none;
      border-bottom: 1px solid #3d3d3d;
    }
  }

  .divider {
    width: 100%;
    border-top: 1px solid #d8d8d8;
    margin-top: 10px;
  }

  .handle {
    padding-top: 11px;
  }

  .handle-btn {
    color: #3d3d3d;
    i {
      padding: 0 6px;
    }
  }
  .rightLine {
    border-right: 1px solid #d8d8d8;
  }

  .common-input {
    ::v-deep {
      input,
      textarea {
        padding-left: 0;
        border-color: transparent;

        &:hover {
          border-color: #008755;
          border-style: dashed;
        }

        &:focus {
          border-color: #008755;
          border-style: solid;
        }
      }

      .el-input-group__prepend {
        border: none;
        background: transparent;
      }
    }
  }
}
</style>
