<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :before-close="beforeClose"
    title="导入工号"
    :modal-append-to-body="false"
    width="70%"
  >
    <div class="upload-content">
      <el-steps :active="step" simple>
        <el-step title="上传文件" icon="el-icon-upload"></el-step>
        <el-step title="导入完成" icon="el-icon-success"></el-step>
      </el-steps>
      <template v-if="step === 1">
        <div class="step">
          <div class="step-head">
            一、请按照数据模板的格式准备要导入的数据。
            <el-button type="text" @click="handleDownload">
              点击下载《{{ "工号导入模板" }}》
            </el-button>
          </div>
          <div class="step-content">导入文件请勿超过2MB (约10,000条数据)</div>
        </div>
        <div class="step">
          <div class="step-head">二、请选择需要导入的文件。</div>
          <div class="step-content">
            <el-upload
              :on-change="handleChange"
              :on-remove="handleRemove"
              :auto-upload="false"
              :file-list="fileList"
              action="#"
            >
              <el-button size="small" type="primary"> 选择文件 </el-button>
            </el-upload>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="success-container">
          <div class="el-icon-circle-check"></div>
          导入完成
        </div>
      </template>
    </div>
    <span slot="footer" class="dialog-footer">
      <template v-if="step === 1">
        <el-button @click="$emit('update:visible', false)">关闭</el-button>
        <el-button
          :disabled="fileList.length === 0"
          type="primary"
          @click="handleImport"
        >
          立即导入
        </el-button>
      </template>
      <template v-else>
        <el-button type="primary" @click="finished"> 完成 </el-button>
      </template>
    </span>
  </el-dialog>
</template>

<script>
// import rw from '@/apis/rw';
import { downloadExcel } from "@/utils/tools";
import api from "@/apis";

export default {
  name: "upload",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    from: {
      type: String,
      default: "app",
    },
  },
  data() {
    return {
      step: 1,
      dialogVisible: false,
      file: null,
      fileList: [],
      apiBefore: {
        "net-sales": "netSales",
        ebitda: "ebitda",
        "new-sales": "newProduct",
      },
      list: [],
    };
  },
  watch: {
    visible(v) {
      this.dialogVisible = v;
      console.log(this.from);
    },
  },
  methods: {
    beforeClose(done) {
      this.step = 1;
      this.fileList = [];
      this.$emit("update:visible", false);
      done();
    },
    handleChange(file) {
      this.fileList = [file];
    },
    handleRemove() {
      this.fileList = [];
    },
    handleDownload() {
      api.downloadJobNo().then((data) => {
        downloadExcel(data, "工号导入模板");
      });
    },
    handleImport() {
      const params = new FormData();
      params.append("file", this.fileList[0].raw);
      const id = localStorage.getItem("questionId");
      params.append("id", id);
      api.importDataJobNo(params).then((data) => {
        this.list = data.data;
        this.step = 2;
        this.$emit("success");
      });
    },
    finished() {
      this.step = 1;
      this.fileList = [];
      this.$emit("update:visible", false);
      this.$emit("list");
    },
  },
};
</script>

<style lang="scss" scoped>
.step {
  margin-top: 20px;

  .step-head {
    font-weight: bold;
  }

  .step-content {
    margin-top: 10px;
  }
}

.success-container {
  line-height: 100px;
  text-align: center;
  font-size: 30px;
  color: #409eff;
}
::v-deep .el-step__arrow::before {
  height: 13px !important;
  width: 2px !important;
}
::v-deep .el-step__arrow::after {
  height: 13px !important;
  width: 2px !important;
}
</style>
