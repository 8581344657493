<template>
  <div
    :class="{ active: active === val.id }"
    class="matrix-scale"
    @click="divClick"
  >
    <div class="head">
      <div class="tag" :id="`tag-${val.id}`">
        <slot name="sort"> </slot>
        矩阵多选
      </div>
      <div class="question-handle">
        <i class="el-icon-delete" @click="$emit('del-question', val)"></i>
      </div>
    </div>
    <div class="input-container">
      <div class="input-item">
        <el-input
          v-model="val.title"
          placeholder="请输入题目标题"
          class="common-input"
          @change="$emit('change', val)"
        />
      </div>
      <div class="input-item">
        <el-input
          v-model="subjectInfo.value"
          placeholder="请输入题目说明(选填)"
          class="common-input"
          @change="$emit('change', val)"
        />
      </div>
      <el-table
        ref="singleTable"
        :data="subjectInfo.tableData"
        highlight-current-row
        @current-change="handleCurrentChange"
      >
        <el-table-column type="index" width="100">
          <template #default="{ row, $index }">
            <div
              contenteditable
              style="display: flex; align-items: center"
              @mouseenter="delEnter"
              @mouseleave="delLeave"
            >
              <div contenteditable @input="titleInput($event, $index)">
                {{ row.rowTitle }}
              </div>
              <i
                class="el-icon-close"
                style="margin-left: 10px; cursor: pointer"
                v-if="subjectInfo.delIsShow"
                @click="delRow($index)"
              ></i>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          v-for="(label, index) in subjectInfo.labelList"
          :key="index"
        >
          <template #header="{ $index }">
            <div
              style="display: flex; align-items: center"
              @mouseenter="subjectInfo.delLabelIsShow = true"
              @mouseleave="subjectInfo.delLabelIsShow = false"
            >
              <div
                @input="labelTitle($event, index)"
                style="width: fit-content; padding: 0 10px"
                contenteditable
              >
                {{ label }}
              </div>
              <i
                v-if="subjectInfo.delLabelIsShow"
                class="el-icon-close"
                style="margin-left: 10px; cursor: pointer"
                @click="delLabel($index)"
              ></i>
            </div>
          </template>
          <template #default="{ row }">
            <!-- <el-radio
              style="padding: 0 20px"
              v-model="row.radio"
              :label="label"
            ></el-radio> -->
            <el-checkbox-group style="padding: 0 20px" v-model="row.checkList">
              <el-checkbox :label="label"></el-checkbox>
            </el-checkbox-group>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="divider"></div>
    <div class="handle">
      <el-button type="text" class="handle-btn">
        <span @click="addRow">
          <i class="el-icon-circle-plus-outline"></i>
          添加行
        </span>
        <span class="rightLine"></span>
        <span @click="editAll(true)">
          <i class="el-icon-folder-add"></i>
          批量编辑
        </span>
      </el-button>
      <el-button type="text" class="handle-btn">
        <span @click="addLabel">
          <i class="el-icon-circle-plus-outline"></i>
          添加选项
        </span>
        <span class="rightLine"></span>
        <span @click="editAll(false)">
          <i class="el-icon-folder-add"></i>
          批量编辑
        </span>
      </el-button>

      <el-drawer
        title="批量编辑"
        :append-to-body="true"
        custom-class="customClass"
        :visible.sync="subjectInfo.drawer"
        :direction="subjectInfo.direction"
        :before-close="handleClose"
      >
        <ul v-if="subjectInfo.isTableOrLabel">
          <li
            v-for="(item, index) in subjectInfo.tableData"
            :key="index"
            class="itemLabelCss"
            ref="allInputRef"
            contenteditable
            @keydown.enter="enter(index)"
            @change="change"
            @input="tableRowTitleInput($event, index)"
          >
            {{ item.rowTitle }}
          </li>
        </ul>
        <ul v-else>
          <li
            v-for="(item, index) in subjectInfo.labelList"
            :key="index"
            class="itemLabelCss"
            ref="allInputRef"
            contenteditable
            @keydown.enter="enter(index)"
            @change="change"
            @input="labelRowTitleInput($event, index)"
          >
            {{ item }}
          </li>
        </ul>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import twoWay from "@/utils/two-way";
// import draggable from "vuedraggable";

export default {
  name: "MatrixRadio",
  mixins: [twoWay],
  props: {
    global: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Number,
      default: 0,
    },
  },
  components: {
    // draggable
  },
  data() {
    return {};
  },
  computed: {
    subjectInfo() {
      return this.val.subjectJson;
    },
  },
  methods: {
    addRow() {
      let arrLength = this.subjectInfo.tableData.length;
      this.subjectInfo.tableData.push({
        checkList: [],
        rowTitle: `行标题${arrLength + 1}`,
      });
    },
    setValue(index, target, value) {
      this.subjectInfo.radioList[index][target] = value;
    },
    selectItem(index) {
      this.subjectInfo.selectedIndex = index;
      this.divClick();
    },
    getStyle(index) {
      return {
        border:
          index === this.subjectInfo.selectedIndex
            ? "1px solid #3f9eff"
            : "none",
      };
    },
    divClick() {
      this.$emit("focus-view", this.val);
      this.$bus.$emit("getCheckList", this.val);
    },
    deleteRadio(selectedIndex) {
      if (this.subjectInfo.radioList.length <= 1) {
        this.$message.error("最少有一项");
      } else {
        this.subjectInfo.radioList.splice(selectedIndex, 1);
      }
    },
    editAll(flag) {
      this.subjectInfo.isTableOrLabel = flag;
      this.subjectInfo.drawer = true;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    async enter(index) {
      await this.addRow();
      this.$nextTick(() => {
        const ref = this.$refs.allInputRef[index + 1];
        ref.focus();
      });
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.subjectInfo.currentRow = val;
    },
    change(e) {
      console.log(e);
    },
    addLabel() {
      // 数组长度
      let arrLength = this.subjectInfo.labelList.length;
      this.subjectInfo.labelList.push(`选项${arrLength + 1}`);
    },
    delEnter() {
      this.subjectInfo.delIsShow = true;
    },
    delLeave() {
      this.subjectInfo.delIsShow = false;
    },
    delRow(index) {
      if (this.subjectInfo.tableData.length <= 1) {
        this.$message.error("矩阵选择题最少有一个子问题");
      } else {
        this.subjectInfo.tableData.splice(index, 1);
        this.$message.success("删除成功");
      }
    },
    delLabel(index) {
      if (this.subjectInfo.labelList.length <= 1) {
        this.$message.error("矩阵选择题最少有一个子问题");
      } else {
        this.subjectInfo.labelList.splice(index - 1, 1);
        this.$message.success("删除成功");
      }
    },
    tableRowTitleInput(e, index) {
      const str = e.target.innerText;
      setTimeout(() => {
        this.subjectInfo.tableData[index].rowTitle = str;
      }, 1000);
    },
    titleInput(e, index) {
      const str = e.target.innerText;
      this.subjectInfo.tableData[index].rowTitle = str;
    },
    labelTitle(e, index) {
      const str = e.target.innerText;
      this.subjectInfo.labelList[index] = str;
    },
    labelRowTitleInput(e, index) {
      const str = e.target.innerText;
      console.log(str, index);
      this.subjectInfo.labelList[index] = str;
    },
  },
};
</script>

<style lang="scss" scoped>
.matrix-scale {
  padding: 10px 43px;
  transition: 0.3s;
  margin-bottom: 20px;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:hover,
  &.active {
    .question-handle {
      display: block;
    }

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  .tag {
    display: inline-block;
    margin-top: 18px;
    margin-bottom: 18px;
    padding: 2px 7px;
    line-height: 27px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 2px 2px 2px 2px;
    font-size: 14px;
    color: #3d3d3d;
  }

  ::v-deep(.customClass) {
    font-size: 16px !important;
  }

  .radio-active {
    border: 1px solid #3f9eff;
  }

  .input-item {
    margin-bottom: 15px;
    display: flex;
    position: relative;

    ::v-deep(.el-input__inner) {
      border: none;
      margin: 0;
      padding: 0;
    }

    ::v-deep(.el-input) {
      min-width: 28px;
    }

    .delete {
      position: absolute;
      right: 0;
    }
  }

  ::v-deep(.el-checkbox__label) {
    display: none;
  }

  ::v-deep(.itemLabelCss) {
    & > div {
      display: none !important;
    }
  }

  .read-write-plaintext-only {
    //纯文本
    -webkit-user-modify: read-write-plaintext-only;
  }

  .delete {
    cursor: pointer;
  }

  .radio-class {
    display: flex;
    align-items: center;

    .text {
      color: #3d3d3d;
      width: 80px;
      cursor: text;

      outline: none;
      // z-index: 9999;
      border-bottom: 1px solid #3d3d3d;
    }
  }

  .divider {
    width: 100%;
    border-top: 1px solid #d8d8d8;
  }

  .handle {
    padding-top: 11px;
  }

  .handle-btn {
    color: #3d3d3d;

    i {
      padding: 0 6px;
    }
  }

  .rightLine {
    border-right: 1px solid #d8d8d8;
  }

  ::v-deep(.all-input) {
    width: 300px;
    height: 10px;
    margin-left: 10px !important;
    font-size: 10px !important;
  }

  .common-input {
    ::v-deep {
      input,
      textarea {
        padding-left: 0;
        border-color: transparent;

        &:hover {
          border-color: #008755;
          border-style: dashed;
        }

        &:focus {
          border-color: #008755;
          border-style: solid;
        }
      }

      .el-input-group__prepend {
        border: none;
        background: transparent;
      }
    }
  }
}
</style>
